import React from "react";
import { Chart, registerables } from "chart.js";
import ChartData from "./ChartData";

Chart.register(...registerables);

const ChartComponent = ({ data, name, time, color,setFilterOpen, startIndex,fetchMoreData,start_time,end_time}) => {
  // Prepare data for the chart

  return (
    <div>
      <ChartData
        data={data}
        time={time}
        label={name}
        color={color}
        name={name}
        setFilterOpen={setFilterOpen}
        startIndex={startIndex}
        fetchMoreData={fetchMoreData}
        start_time={start_time}
        end_time={end_time}
      />
    </div>
  );
};

export default ChartComponent;
