import {
  Alert,
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Link,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Axios from "../../helper/axios";

import { useNavigate } from "react-router-dom";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link
        color="secondary"
        href="https://www.thekaspertech.com/"
        sx={{ textDecoration: "none", fontWeight: 600 }}
      >
        KasperTech
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function ResetPassword() {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState();
  const [password, setPassword] = useState("");
  const [severity, setSeverity] = useState("");

  let navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  const handleReset = async (e) => {
    e.preventDefault();
    if (password.length < 6) {
      setOpen(true);
      setSeverity("error");
      setMessage("Minimum 6 characters required");
      return;
    }
    try {
      const res = await Axios.post("/auth/reset-password", {
        password: password,
      });
      localStorage.removeItem("reset_token");
      setMessage(res.data.message);
      setOpen(true);
      setLoading(false);
      setSeverity("success");
      navigate("/login");
    } catch (error) {
      setOpen(true);
      setMessage(error.response.data.error);
      setLoading(false);
      setSeverity("error");
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#213240",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: { xs: "none", sm: "center" },
      }}
    >
      <Box
        sx={{
          width: { xs: "80%", sm: 500 },
          mb: 4,
          mt: { xs: "15vh", sm: 0 },
        }}
      >
        <img
          src="/assets/logo/Logo-Lateral-Light.svg"
          alt="Luminox by KasperTech"
          width="100%"
          height="auto"
        />
      </Box>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          height: { xs: "75vh", sm: "fit-content" },
          borderRadius: { xs: "40px 40px 0 0", sm: "40px" },
        }}
        elevation={8}
      >
        <Container component="main" maxWidth="md">
          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleClose}
              severity={severity}
              sx={{ width: "100%" }}
            >
              {message}
            </Alert>
          </Snackbar>

          <Typography
            sx={{ fontSize: "2.5rem", fontWeight: 600, mt: { xs: 6, sm: 4 } }}
            color="secondary"
          >
            Reset Password
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              component="form"
              onSubmit={handleReset}
              noValidate
              sx={{ mt: "5%" }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                size="large"
                variant="contained"
                color="secondary"
                sx={{ mt: 3, mb: 2 }}
              >
                {loading ? (
                  <>
                    Loading{" "}
                    <CircularProgress
                      color="inherit"
                      size={20}
                      style={{ marginLeft: "8px" }}
                    />
                  </>
                ) : (
                  "Reset Password"
                )}
              </Button>

              <Copyright
                sx={{
                  display: { xs: "none", sm: "block" },
                  mt: 3,
                  mb: 4,
                }}
              />
            </Box>

            <Copyright
              sx={{
                position: "absolute",
                bottom: 0,
                mb: 1,
                display: { sm: "none" },
              }}
            />
          </Box>
        </Container>
      </Card>
    </Box>
  );
}
