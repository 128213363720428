import React, { useState } from "react";
import { TextField, Grid, Typography, Button } from "@mui/material";
import { bulkPublish } from "../../../../../api/device";
import TimerDialog from "./TimerDialog";
import Swal from "sweetalert2";

const TimeSwitch = ({ groupId,device_data, setAlertOpen, setMessage, setSeverity, timerDialogOpen,setTimerDialogOpen,handleTimerDialogClose }) => {
  const [timer, setTimer] = useState({
    startTime: "9999",
    endTime: "9999",
  });
  const [timer2, setTimer2] = useState({
    startTime: "9999",
    endTime: "9999",
  });
  const [fixLoading, setFixLoading] = useState(false);
  const [clearLoading, setClearLoading] = useState(false);

  const showmodal = () => {
    handleTimerDialogClose();
    if(timer2.startTime === "9999"){
    let devices=[];
    device_data.map((device)=>{
      if(device.device_data.timerStatus2!="9999,9999"){
        devices.push(device.device_id);
      }
    })
    let message= `Timer-2 for devices ${devices.join(",")} is cleared.`
    Swal.fire({
      title: "Action Alert",
      text: `${message}`,
      icon: "warning",
      showConfirmButton: true,
      confirmButtonText: "Okay",
      confirmButtonColor: "#213240",
    }).then((result) => {
      setAlertOpen(false);
    });
  }
  };

  const setTime = () => {
    setFixLoading(true);
    if (timer.startTime === "9999" || timer.endTime === "9999") {
      setAlertOpen(true);
      setSeverity("error");
      setMessage("Please enter both start time and end time");
      setFixLoading(false);
      return;
    }
    let time_final =
      timer.startTime.replace(":", "") +
      "," +
      timer.endTime.replace(":", "");
      if ((timer2.startTime !== "9999" && timer2.endTime==="9999") || (timer2.endTime !== "9999" && timer2.startTime==="9999")) {
        setAlertOpen(true);
        setSeverity("error");
        setMessage("Please enter both start time and end time");
        setFixLoading(false);
        return;
      }
    let time2_final =
      timer2.startTime.replace(":", "") +
      "," +
      timer2.endTime.replace(":", "");

    let data = {
      channel: "timer",
      data: time_final,
    };

    bulkPublish(
      groupId,
      data,
      "",
      setAlertOpen,
      setMessage,
      setSeverity
    );
    let data2={
        channel: "timer2",
        data: time2_final,
    }
    bulkPublish(
      groupId,
      data2,
      setFixLoading,
      setAlertOpen,
      setMessage,
      setSeverity,
      showmodal
    );
  };

  const clearTimer = () => {
    setClearLoading(true);
    let data = {
      channel: "timer",
      data: "9999,9999",
    };

    bulkPublish(
      groupId,
      data,
      "",
      setAlertOpen,
      setMessage,
      setSeverity
    );

    let data2={
      channel: "timer2",
      data: "9999,9999",
      }

      bulkPublish(
        groupId,
        data2,
        "",
        setAlertOpen,
        setMessage,
        setSeverity,
        handleTimerDialogClose
      );
    setTimer({ startTime: "9999", endTime: "9999" });
    setTimer2({ startTime: "9999", endTime: "9999" });
  };

  return (
    <>
    {/* <Grid container sx={{ marginTop: "10px" }} spacing={2}> */}
      <Grid item xs={2} md={1.5}>
        <Typography variant="body1" >Timer</Typography>
      </Grid>
      {/* <Grid item xs={12} md={5}>
        <Grid container spacing={1}>
          <Grid item xs={6} md={6}>
            <TextField
              id="time"
              label="On Time"
              type="time"
              fullWidth
              size="small"
              value={timer.startTime}
              onClick={()=>setTimerDialogOpen(true)}
              // onChange={(e) => {
              //   setMasterTimer({ ...masterTimer, startTime: e.target.value });
              // }}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <TextField
              id="time"
              label="Off Time"
              type="time"
              fullWidth
              size="small"
              value={timer.endTime}
              onClick={()=>setTimerDialogOpen(true)}
              // onChange={(e) => {
              //   setMasterTimer({ ...masterTimer, endTime: e.target.value });
              // }}
            />
          </Grid>
        </Grid>
      </Grid> */}
      <Grid item xs={12} md={4}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              fullWidth
              disabled={clearLoading}
              // onClick={setTime}
              onClick={()=>setTimerDialogOpen(true)}
            >
              Update Timer
            </Button>
          </Grid>
          {/* <Grid item xs={6}>
            <Button
              variant="outlined"
              fullWidth
              disabled={fixLoading}
              // onClick={clearTimer}
              onClick={()=>setTimerDialogOpen(true)}
            >
              {clearLoading ? "Clearing.." : "Clear"}
            </Button>
          </Grid> */}
        </Grid>
      </Grid>
    {/* </Grid> */}
              {timerDialogOpen&& <TimerDialog open={timerDialogOpen} onClose={handleTimerDialogClose}
              timer={timer}
              setTimer={setTimer}
              timer2={timer2}
              setTimer2={setTimer2}
              handleTime={setTime}
              clearTime={clearTimer}
              timerLoad={fixLoading}
              clearLoad={clearLoading}
              setSeverity={setSeverity}
              setMessage={setMessage}
              setAlertOpen={setAlertOpen}
              />}
              </>
  );
};

export default TimeSwitch;
