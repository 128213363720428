import React, { forwardRef } from "react";
import {
  Avatar,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  ElectricMeter,
  Hardware,
  Devices,
  AlarmOn,
  ReportGmailerrorred,
} from "@mui/icons-material";
import Fade from "react-reveal/Fade";
import "./styles/features.css";

const Features = forwardRef((props, ref) => {
  return (
    <div
      style={{
        minHeight: "calc(100vh - 64px)",
        backgroundColor: "#fff",
      }}
      ref={ref}
    >
      <Grid
        container
        spacing={4}
        sx={{
          padding: "5%",
          marginTop: "auto",
          display: "flex",
          alignItems: "center",
        }}
      >
        {/* Device Image */}
        <Grid
          item
          xs={12}
          lg={5}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Fade left delay={200}>
            <img
              src="/assets/landing/device.png"
              alt="Device"
              className="productImg"
            />
          </Fade>
        </Grid>

        {/* Features */}
        <Grid item xs={12} lg={7}>
          <List>
            <Fade right cascade delay={400} duration={1250}>
              <div>
                {/* Feature 1  */}
                <ListItem
                  alignItems="flex-start"
                  sx={{
                    width: "100%",
                    bgcolor: "#F5F5F5",
                    borderRadius: 5,
                    mb: 3,
                  }}
                >
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        height: 40,
                        width: 40,
                        mr: 3,
                        backgroundColor: "#1d1f21",
                      }}
                    >
                      <Hardware />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography sx={{ fontWeight: 600 }}>
                        Easy to install
                      </Typography>
                    }
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          Installing Luminox is just a 3 step process,{" "}
                          <b>Connect,</b> <b>Initialise</b> and <b>Control</b>,
                          yes we are done! The best part about Luminox, is that
                          it is plug and play system, that means we don’t need
                          to make any change to the current architecture, isn’t
                          it amazing!
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>

                {/* Feature 2  */}
                <ListItem
                  alignItems="flex-start"
                  sx={{
                    width: "100%",
                    bgcolor: "#F5F5F5",
                    borderRadius: 5,
                    mb: 3,
                    ml: { lg: 4, xs: 0 },
                  }}
                >
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        height: 40,
                        width: 40,
                        mr: 3,
                        backgroundColor: "#1d1f21",
                      }}
                    >
                      <Devices />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography sx={{ fontWeight: 600 }}>
                        Control from anywhere
                      </Typography>
                    }
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          With Luminox, you can control your street lightning
                          system with a single click from anywhere around the
                          world. Not only this, Luminox also provides real-time
                          feedback of the health and the status of the street
                          lights right on your screens!
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>

                {/* Feature 3  */}
                <ListItem
                  alignItems="flex-start"
                  sx={{
                    width: "100%",
                    bgcolor: "#F5F5F5",
                    borderRadius: 5,
                    mb: 3,
                    ml: { lg: 8, xs: 0 },
                  }}
                >
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        height: 40,
                        width: 40,
                        mr: 3,
                        backgroundColor: "#1d1f21",
                      }}
                    >
                      <AlarmOn />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography sx={{ fontWeight: 600 }}>
                        Auto Mode and Timer Mode
                      </Typography>
                    }
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          Luminox comes with multiple features including Auto
                          mode and Timer mode. Auto mode allows you to control
                          the lights autonomously with help of the inbuilt
                          sensors. Timer mode lets you configure schedule to
                          turn on and off the street lights automatically.
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>

                {/* Feature 4  */}
                <ListItem
                  alignItems="flex-start"
                  sx={{
                    width: "100%",
                    bgcolor: "#F5F5F5",
                    borderRadius: 5,
                    mb: 3,
                    ml: { lg: 4, xs: 0 },
                  }}
                >
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        height: 40,
                        width: 40,
                        mr: 3,
                        backgroundColor: "#1d1f21",
                      }}
                    >
                      <ReportGmailerrorred />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography sx={{ fontWeight: 600 }}>
                        Fault Detection
                      </Typography>
                    }
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          One of the most amazing thing about Luminox is its
                          fault detection and prediction algorithm. Luminox
                          detects faulty street lights and generates alert
                          making the maintainence process easier and help in
                          reducing energy wastage due to faulty street lights.
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>

                {/* Feature 5  */}
                <ListItem
                  alignItems="flex-start"
                  sx={{
                    width: "100%",
                    bgcolor: "#F5F5F5",
                    borderRadius: 5,
                    mb: 3,
                  }}
                >
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        height: 40,
                        width: 40,
                        mr: 3,
                        backgroundColor: "#1d1f21",
                      }}
                    >
                      <ElectricMeter />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography sx={{ fontWeight: 600 }}>
                        In-Built Protection Circuit
                      </Typography>
                    }
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          Luminox comes with in-built over-voltage and
                          over-current protection circuits to protect itself and
                          the street lights in case of current and voltage
                          spikes.
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </div>
            </Fade>
          </List>
        </Grid>
      </Grid>
    </div>
  );
});

export default Features;
