import React, { useContext, useState } from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { UserContext } from "../../../../../context/UserContext";

export default function ToggleButtons({
  auto,
  modeStatus,
  Id,
  client,
  companyId,
  block,
  disabled,
  setdisabled,
  setdisabledmessage,
  active,
  setSeverity,
  setMessage,
  setAlertOpen,
  showmodal,
  initialization
}) {
  const [value, setValue] = useState(auto === "0" ? "manual" : "auto");
  const { userData } = useContext(UserContext);

  React.useEffect(() => {
    let val;
    if (auto === "0") {
      val = "auto";
    } else {
      val = "manual";
    }
    setValue(val);
  }, [auto, client, modeStatus]);

  const handleModeChange = (event, value) => {
    var options = {
      retain: true,
      qos: 1,
    };
    let mode;
    setdisabled(true);
    setdisabledmessage(
      "An Operation is going on. Please wait to perform another action."
    );
    if (active === "offline") {
      // setAlertOpen(true);
      // setSeverity("error");
      // setMessage("Your Operation will be Performed Once device comes Online.");
      showmodal("Your Operation will be Performed Once device comes Online.");
    }
    if (value === "auto") {
      mode = "0";
      let data = mode;
      let publish_data = {
        data: data,
        token: userData.user.token,
      };
      client.publish(`${Id}/mode`, JSON.stringify(publish_data), options);
    } else {
      mode = "1";
      let data = mode;
      let publish_data = {
        data: data,
        token: userData.user.token,
        // metaData:"Published by App"
      };
      client.publish(`${Id}/mode`, JSON.stringify(publish_data), options);
    }
    // setValue(!value);
  };

  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      onChange={handleModeChange}
      disabled={block || disabled||initialization}
      // style={{ backgroundColor: props.modeStatus === "1" && "lightskyblue" }}
    >
      <ToggleButton
        value="manual"
        size="small"
        // disabled={disabled}
        style={
          modeStatus === "1"
            ? { color: "#fff", fontWeight: 600, backgroundColor: "#90aec6" }
            : { color: "", fontWeight: 600 }
        }
      >
        Manual
      </ToggleButton>
      <ToggleButton
        value="auto"
        size="small"
        // disabled={disabled}
        style={
          modeStatus === "0"
            ? { color: "#fff", fontWeight: 600, backgroundColor: "#90aec6" }
            : { color: "", fontWeight: 600, width: 66 }
        }
      >
        Auto
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
