// apis for role ops

import axiosIntance from "../helper/axios";

const fetchAllRoles = (company_id,setRoleData, setRoleLoading) => {
  axiosIntance
    .get(`/role/fetch/${company_id}`)
    .then((res) => {
      setRoleData(res.data.data);
      setRoleLoading(false);
    })
    .catch((err) => {
      console.log(err);
      setRoleLoading(false);
    });
};

export { fetchAllRoles };
