import * as React from "react";
import { styled } from "@mui/material/styles";
import { AppBar, ButtonBase, Toolbar } from "@mui/material";
import { Hive, Home, Person } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import theme from "../../theme";
const secondary = theme.palette.secondary.main;

const ButtomNavButton = styled(ButtonBase)(({ activeclass }) => ({
  width: "100%",
  height: "56px",
  borderTop: activeclass === "active" ? `2px solid ${secondary}` : "none",
}));

export default function BottomAppBar({notificationcount}) {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;

  return (
    <React.Fragment>
      <AppBar
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          top: "auto",
          bottom: 0,
          display: { xs: "block", sm: "none" },
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-around",
            height: "100%",
            padding: 0,
          }}
        >
          <ButtomNavButton
            activeclass={path === "/dashboard/master" ? "active" : ""}
            onClick={() => navigate("/dashboard/master")}>
            <Hive color="secondary" />
          </ButtomNavButton>
          <ButtomNavButton
            activeclass={path === "/dashboard" ? "active" : ""}
            onClick={() => navigate("/dashboard")}
          >
            <Home color="secondary" />
          </ButtomNavButton>
          <ButtomNavButton
            activeclass={path === "/dashboard/profile" ? "active" : ""}
            onClick={() => navigate("/dashboard/profile")}
          >
            <Person color="secondary" />
          </ButtomNavButton>
          {/* <ButtomNavButton
            activeClass={path === "/app/notifications" ? "active" : ""}
            onClick={() => navigate("/app/notifications")}
          >
            <Badge badgeContent={notificationcount.toString()} color="primary">
            <NotificationsNoneIcon/>
            </Badge>
          </ButtomNavButton> */}
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}
