/* eslint-disable no-redeclare */
import React, { useContext, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Snackbar,
  Alert,
  Typography,
  Divider,
  Grid
} from "@mui/material";

import ToggleSwitch from "./elements/ToggleSwitch";
// import ModeSwitch from "./elements/ModeSwitch";
import TimeSwitch from "./elements/TimeSwitch";
import { UserContext } from "../../../../context/UserContext";

const MasterControl = ({ data }) => {
  // eslint-disable-next-line no-unused-vars
  const { userData } = useContext(UserContext);

  const [alertOpen, setAlertOpen] = React.useState(false);
  const [severity, setSeverity] = useState();
  const [message, setMessage] = useState("");
  const handleClose = () => {
    setAlertOpen(false);
  };
  const [timerDialogOpen,setTimerDialogOpen]= useState(false);
  const handleTimerDialogClose=()=>{
    setTimerDialogOpen(false);
  }

  // const [masterMode, setMasterMode] = useState("1");

  return (
    <Box sx={{ minWidth: 275 }}>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <Card>
        <CardContent>
          <Typography variant="h5">{data.group_name}</Typography>
          <Typography inline="true" variant="caption" color="GrayText" component="div">
            Code - {data.group_code}
          </Typography>
          <Typography
            inline="true"
            variant="caption"
            color="InactiveCaptionText"
            align="right"
            component="div"
          >
            No of Devices: {data.device_data.length}
          </Typography>

          <Divider style={{ marginBottom: "16px" }} />
          <Grid container sx={{ marginTop: "10px" ,alignItems:"center"}} spacing={2}>
          <ToggleSwitch
            groupId={data._id}
            setSeverity={setSeverity}
            setAlertOpen={setAlertOpen}
            setMessage={setMessage}
          />

          <TimeSwitch
            groupId={data._id}
            device_data={data.device_data}
            setSeverity={setSeverity}
            setAlertOpen={setAlertOpen}
            setMessage={setMessage}
            timerDialogOpen={timerDialogOpen}
            setTimerDialogOpen={setTimerDialogOpen}
            handleTimerDialogClose={handleTimerDialogClose}
          />
          </Grid>
          {/* <div style={{ marginTop: 16 }}>
            <ModeSwitch masterMode={masterMode} companyId={companyId} />
          </div>
          <div style={{ marginTop: 24 }}>
            <TimeSwitch
              masterTimer={masterTimer}
              setMasterTimer={setMasterTimer}
            />
            <Button
              variant="contained"
              style={{
                margin: "16px 16px 0 0",
                color: "#fff",
              }}
              onClick={handleTime}
              sx={{ width: { xs: "calc((100% - 16px)/2)", md: "200px" } }}
            >
              SET
            </Button>
            <Button
              variant="contained"
              style={{
                color: "#fff",
                marginTop: 16,
              }}
              onClick={clearTime}
              sx={{ width: { xs: "calc((100% - 16px)/2)", md: "200px" } }}
            >
              CLEAR
            </Button>
          </div> */}
        </CardContent>
      </Card>
    </Box>
  );
};

export default MasterControl;
