// APIs for group

import axiosIntance from "../helper/axios";

const createGroup = (
  data,
  setSeverity,
  setMessage,
  setAlertOpen,
  handleFormClose
) => {
  axiosIntance
    .post("/group/create", data)
    .then((res) => {
      setSeverity("success");
      setMessage(res.data.message);
      setAlertOpen(true);
      handleFormClose();
    })
    .catch((err) => {
      console.log(err.response.data.error);
      setAlertOpen(true);
      setSeverity("error");
      setMessage(err.response.data.error);
    });
};

const fetchAllGroups = (
  company_id,
  setGroupData,
  setGrpLoading,
  table,
  page,
  limit,
  setTotal,
  searchQuery
) => {
  if (table === "true") {
    // setGrpLoading(true);
    axiosIntance
      .get(`/group/by/companyId/${company_id}?table=${table}&page=${page + 1}&limit=${limit}&searchTerm=${searchQuery}`)
      .then((res) => {
        setGroupData(res.data.data.group_data);
        setTotal(res.data.data.metadata.total_results);
        setGrpLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setGrpLoading(false);
      });
  } else {
    axiosIntance
      .get(`/group/by/companyId/${company_id}?table=${table}`)
      .then((res) => {
        setGroupData(res.data.data);
        setGrpLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setGrpLoading(false);
      });
  }
};

const updateGroup = (
  groupId,
  grp,
  setSeverity,
  setMessage,
  setAlertOpen,
  handleFormClose
) => {
  axiosIntance
    .put(`/group/update/${groupId}`, grp)
    .then((res) => {
      setSeverity("success");
      setMessage(res.data.message);
      setAlertOpen(true);
      handleFormClose();
    })
    .catch((err) => {
      console.log(err.response.data.error);
      setAlertOpen(true);
      setSeverity("error");
      setMessage(err.response.data.error);
    });
};

const getGroupWiseDevices = (
  company_id,
  setGroup,
  setLoading,
  setError,
  setofflinedevicecount,
  setonlinedevicecount
) => {
  axiosIntance
    .get(`/group/wise/devices/${company_id}`)
    .then((res) => {
      setGroup(res.data.data);
      // console.log(res.data);
      if(setofflinedevicecount){
      let onlineCount = 0;
      let offlineCount = 0;
      const processedDeviceIds = new Set();
      res.data.data.forEach((group) => {
        group.device_data.forEach((device) => {
          const { device_id, status } = device;
          if (!processedDeviceIds.has(device_id)) {
            processedDeviceIds.add(device_id);
            if (status === "online") {
              onlineCount++;
            } else if (status === "offline") {
              offlineCount++;
            }
          }
        });
      });
      setonlinedevicecount(onlineCount);
      setofflinedevicecount(offlineCount);
    }
      setLoading(false);
    })
    .catch((err) => {
      if(setError)setError(err?.response?.data?.error);
      setLoading(false);
    });
};

const getDevicesInGroup = async (groupId, setDeviceData, setDeviceLoad) => {
  axiosIntance
    .get(`/group/devices/${groupId}`)
    .then((res) => {
      // console.log(res.data);
      setDeviceData(res.data.data.device_array);
      setDeviceLoad(false);
    })
    .catch((err) => {
      setDeviceLoad(false);
      console.log(err);
    });
};
const fetchUserGroups = async (user_id,company_id, setGroupData, setLoading) => {
  axiosIntance
    .get(`/group/fetch/all/${user_id}/${company_id}`)
    .then((res) => {
      // console.log(res.data);
      setGroupData(res.data.data);
      setLoading(false);
    })
    .catch((err) => {
      setLoading(false);
      console.log(err);
    });
};
export {
  createGroup,
  fetchAllGroups,
  updateGroup,
  getGroupWiseDevices,
  getDevicesInGroup,
  fetchUserGroups
};
