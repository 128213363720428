import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Tabs,
  Tab,
  Box,
  TextField,
  CircularProgress,
  Badge,
} from "@mui/material";

function TimerDialog({
  open,
  onClose,
  timer,
  setTimer,
  timer2,
  setTimer2,
  handleTime,
  clearTime,
  timerLoad,
  clearLoad,
  setSeverity,
    setMessage,
    setAlertOpen,
}) {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleTimerSet = () => {
    if(timer2.startTime===""||timer2.endTime===""||timer.startTime===""||timer.endTime===""){
      setSeverity("error");
      setMessage("Enter a valid timer value");
      setAlertOpen(true);
      return;
    }
      if(timer2.startTime!=="9999"&&timer2.endTime!=="9999"){
      const [startHours, startMinutes] = timer2.startTime.split(':').map(Number);
      const startTime = startHours * 60 + startMinutes;
      const [startHours2, startMinutes2] = timer.startTime.split(':').map(Number);
      const startTime2 = startHours2 * 60 + startMinutes2;

      const [endHours, endMinutes] = timer2.endTime.split(':').map(Number);
      const endTime = endHours * 60 + endMinutes;
      const [endHours2, endMinutes2] = timer.endTime.split(':').map(Number);
      const endTime2 = endHours2 * 60 + endMinutes2;

      if((startTime2 >= startTime && startTime2<= endTime) || (endTime2 >= startTime && endTime2 <= endTime)||(startTime >= startTime2 && startTime<= endTime2) || (endTime >= startTime2 && endTime <= endTime2)){
        setSeverity("error");
        setMessage("Timer-1 should not be in range of Timer-2");
        setAlertOpen(true);
        return;
      }
      }
      handleTime();
  };
  const handleTimerClear = () => {
      clearTime();
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Timer Dialog</DialogTitle>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Timer" />
        <Tab label="Timer-2" disabled={(timer.startTime==="9999"&&timer.endTime==="9999")||!timer}/>
      </Tabs>
      <DialogContent>
        {selectedTab === 0 && (
          <Box
            component="form"
            sx={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            <TextField
              id="time"
              label="On Time"
              type="time"
              size="small"
              placeholder="HH:mm"
              value={timer.startTime}
              InputLabelProps={{
                shrink: true,
                style: {
                  color:"#e0e0e0",
                },
              }}
              style={{ marginBottom: 16 }}
              onChange={(e) => {
                setTimer({ ...timer, startTime: e.target.value });
              }}
            />
            <TextField
              id="time"
              label="Off Time"
              type="time"
              size="small"
              value={timer.endTime}
              InputLabelProps={{
                shrink: true,
                style: {
                  color:"#e0e0e0",
                },
              }}
              onChange={(e) => {
                setTimer({ ...timer, endTime: e.target.value });
              }}
            />
          </Box>
        )}
        {selectedTab === 1 && (
          <Box
            component="form"
            sx={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            <TextField
              id="time"
              label="On Time"
              type="time"
              size="small"
              placeholder="HH:mm"
              value={timer2.startTime}
              InputProps={{inputProps:{
                min:'9:00',
                max:'16:00'
              }
              }}
              InputLabelProps={{
                shrink: true,
                style: {
                  color:"#e0e0e0",
                },
              }}
              style={{ marginBottom: 16 }}
              onChange={(e) => {
                setTimer2({ ...timer2, startTime: e.target.value });
              }}
            />
            <TextField
              id="time"
              label="Off Time"
              type="time"
              size="small"
              value={timer2.endTime}
              InputLabelProps={{
                shrink: true,
                style: {
                  color:"#e0e0e0",
                },
              }}
              onChange={(e) => {
                setTimer2({ ...timer2, endTime: e.target.value });
              }}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
      <Badge
                    color="success"
                    badgeContent="click"
                    anchorOrigin={{
                      horizontal: "left",
                      vertical: "top",
                    }}
                    invisible={
                      selectedTab===0?!(
                        (timer.startTime !== "9999") ||
                        (timer.endTime !== "9999")
                      ):!((timer2.startTime !== "9999") ||
                      (timer2.endTime !== "9999"))
                    }
                  >
        <Button
          variant="contained"
          style={{
            background: "#90aec6",
            color: "#fff",
            fontWeight: 600,
            marginRight:"16px"
          }}
          onClick={handleTimerSet}
        >
          {timerLoad ? (
                        <CircularProgress
                          style={{ color: "white" }}
                          size={21}
                        />
                      ) : (
          "SET"
          )}
        </Button>
        </Badge>
        <Button
          variant="contained"
          style={{
            background: "#90aec6",
            color: "#fff",
            fontWeight: 600,
          }}
          onClick={handleTimerClear}
        >
          {clearLoad ? (
                        <CircularProgress
                          style={{ color: "white" }}
                          size={21}
                        />
                      ) : (
          "CLEAR"
                      )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TimerDialog;
