import React, { useContext, useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Tooltip,
  Avatar,
  Menu,
  MenuItem,
  Badge,
} from "@mui/material";

import { useNavigate, useLocation } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import theme from "../../theme";
import { removeNotificationToken } from "../../api/notification";

const secondary = theme.palette.secondary.main;
const primary = theme.palette.primary.main;

const Navbar = ({ drawerWidth, handleDrawerToggle,notificationcount }) => {
  const { setUserData, userData } = useContext(UserContext);
  let navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const getPageName = (path) => {
    switch (path) {
      case "/dashboard":
        return "Home";
      case "/dashboard/plans":
        return "Plans";
      case "/dashboard/transactions":
        return "Transactions";
      case "/dashboard/profile":
        return "Profile";
      case "/dashboard/user":
        return "User";
      case "/dashboard/group":
        return "Group";
      default:
        return "";
    }
  };

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    // window.addEventListener('storage', handleStorageChange);
  });

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const toProfile = () => {
    setAnchorElUser(null);
    navigate("/dashboard/profile");
  };

  const handleLogout = () => {
    if (localStorage.getItem("notification_token"))
      removeNotificationToken(
        userData.user._id,
        localStorage.getItem("notification_token"),
        localStorage.getItem("auth-token")
      );
    localStorage.clear();
    setUserData("");

    navigate("/login");
  };

  return (
    <AppBar
      position="fixed"
      color="secondary"
      elevation={isMobile ? 8 : 0}
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
        backgroundColor: { sm: "#f4f9f9" },
      }}
    >
      <Toolbar style={{ paddingRight: "10px" }}>
        <IconButton
          color="inherit"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 1, display: { sm: "none" } }}
        >
          <img
            src="/assets/logo/Luminox-Icon-White.svg"
            alt="Luminox"
            style={{
              width: "auto",
              height: 30,
            }}
          />
        </IconButton>
        <Typography
          variant="h4"
          sx={{
            fontFamily: "Ubuntu",
            color: { xs: "#fff" },
            display: { sm: "none" },
          }}
        >
          {getPageName(path)}
        </Typography>

        <Typography sx={{ flexGrow: 1 }} />
        <Tooltip title="Notifications">
          <Badge
            badgeContent={notificationcount.toString()}
            style={{
              color: `${window.innerWidth < 720 ? "white" : "#000000"}`,
              right: "5px",
            }}
          >
            <NotificationsNoneIcon
              style={{
                cursor: "pointer",
                color: `${window.innerWidth < 720 ? "white" : "#213240"}`,
              }}
              onClick={() => navigate("/app/notifications")}
            />
          </Badge>
        </Tooltip>
        <Box sx={{ flexGrow: 0, display: { xs: "none", sm: "block" } }}
        style={{ marginLeft: "10px" }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tooltip title={userData.user.full_name}>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, margin: 1 }}>
                <Avatar
                  alt={userData.user.full_name}
                  src="/"
                  sx={{
                    backgroundColor: { xs: primary, sm: secondary },
                    color: { xs: secondary, sm: "#fff" },
                    height: { xs: 30, sm: 34 },
                    width: { xs: 30, sm: 34 },
                  }}
                />
              </IconButton>
            </Tooltip>
          </div>

          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem onClick={toProfile}>
              <Typography textAlign="center">Profile</Typography>
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <Typography textAlign="center">Logout</Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
