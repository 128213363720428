import React, { useContext } from "react";
import styled from "@emotion/styled";
import {
  Typography,
  Breadcrumbs,
  Divider,
  Link,
  Paper,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
} from "@mui/material";
import {
  LocationCity,
  Person,
  PhoneIphone,
  QrCode,
  WorkspacePremium,
} from "@mui/icons-material";
import { UserContext } from "../../context/UserContext";
import { useNavigate } from "react-router-dom";

const PageTitle = styled(Typography)`
  margin: 0px 0px 0.35em;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.25;
`;

const PathLink = styled(Link)`
  margin: 0px;
  color: rgb(55, 111, 208);
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
`;

const ActiveLink = styled(Typography)`
  margin: 0px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
`;

const Profile = () => {
  const { userData } = useContext(UserContext);
  const navigate= useNavigate();
  return (
    <div>
      <PageTitle>Profile</PageTitle>
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{ display: { xs: "none", lg: "block" } }}
      >
        <PathLink underline="hover" href="/dashboard">
          Dashboard
        </PathLink>
        <ActiveLink>Profile</ActiveLink>
      </Breadcrumbs>
      <Divider style={{ marginTop: 16, marginBottom: 16 }} />

      <Paper sx={{ p: 4, mb: { xs: 8, sm: "none" } }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Typography variant="h5">USER DETAILS</Typography>
            <Divider sx={{ mt: 2 }} />
            <List sx={{ marginLeft: -2 }}>
              <ListItem>
                <ListItemIcon>
                  <Person color="primary" />
                </ListItemIcon>
                <ListItemText>{userData.user.full_name}</ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <PhoneIphone color="primary" />
                </ListItemIcon>
                <ListItemText>
                  +91{" "}
                  {userData.user.phone_no.slice(0, 5) +
                    " " +
                    userData.user.phone_no.slice(5, 10)}
                </ListItemText>
              </ListItem>
              {userData.user.role_id && (
                <ListItem>
                  <ListItemIcon>
                    <WorkspacePremium color="primary" />
                  </ListItemIcon>
                  <ListItemText>{userData.user.role_id.role_name}</ListItemText>
                </ListItem>
              )}
              <ListItem>
                <ListItemIcon>
                  <LocationCity color="primary" />
                </ListItemIcon>
                <ListItemText>
                  <div style={{display:"flex",alignItems:"center"}}>
                  {userData.user.company_id.company_name}
                  <Button sx={{ml:1}} variant="outlined" color="error" onClick={()=>navigate("/company",{state:{token:userData.token,userData:userData.companies}})}>Change Site</Button></div>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <QrCode color="primary" />
                </ListItemIcon>
                <ListItemText>
                  {userData.user.company_id.company_code}
                </ListItemText>
              </ListItem>
            </List>
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <Typography variant="h5">SECURITY</Typography>
            <Divider sx={{ mt: 2 }} />
            <Box sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
              <label>Old Password</label>
              <TextField placeholder="Password" sx={{ mt: 1 }} size="small" />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
              <label>New Password</label>
              <TextField placeholder="Password" sx={{ mt: 1 }} size="small" />
            </Box>
            <Button
              variant="contained"
              sx={{ color: "#fff", mt: 3, float: "left" }}
            >
              Change Password
            </Button>
          </Grid> */}
        </Grid>
      </Paper>
    </div>
  );
};

export default Profile;
