import React from "react";
import "./loaderStyles.css";

const Loader = ({ data }) => {
  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <svg
        width="100"
        height="100"
        viewBox="0 0 320 323"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g className="wifi">
          <path
            className="wifi-top"
            d="M71.2747 60.1959L53.98 40.2807C86.9972 10.933 125.973 0.224634 158.272 0.0034839C206.06 -0.323714 246.318 22.4629 265.709 40.2812L247.89 60.1959C216.667 34.8703 185.743 26.9113 158.272 27.1795C119.346 27.5597 88.0453 44.9983 71.2747 60.1959Z"
            fill="#213240"
          />
          <path
            className="wifi-middle"
            d="M100.595 93.4331L81.4912 71.8998C146.236 19.295 212.812 50.1578 237.728 72.0764L218.619 93.4331C169.162 54.3167 119.329 77.1346 100.595 93.4331Z"
            fill="#213240"
          />
          <path
            className="wifi-bottom"
            d="M128.4 125.189L110.582 104.749C117.22 98.6352 137.834 86.5054 160.893 86.9247C183.953 87.3439 201.772 98.4541 208.585 104.749L190.242 125.189C186.573 122.038 177.245 115.12 160.893 114.701C144.542 114.281 133.641 120.99 128.4 125.189Z"
            fill="#213240"
          />
        </g>
        <path
          className="bulb"
          d="M159.612 212.961C186.819 212.961 208.875 190.905 208.875 163.697C208.875 157.827 207.848 152.197 205.965 146.976L225.824 124.661C232.583 136.101 236.462 149.446 236.462 163.697C236.462 206.141 202.055 240.548 159.612 240.548C117.168 240.548 82.7612 206.141 82.7612 163.697C82.7612 149.338 86.6996 135.898 93.555 124.399L113.37 146.673C111.416 151.979 110.349 157.714 110.349 163.697C110.349 190.905 132.405 212.961 159.612 212.961Z"
          fill="#213240"
        />
        <g className="holder">
          <path
            className="holder-first"
            d="M121.897 277.233V246.791C156.778 258.968 185.317 251.865 195.226 246.791V277.233C166.687 284.191 134.449 280.132 121.897 277.233Z"
            fill="#213240"
          />
          <path
            className="holder-second"
            d="M121.897 318.442V288C156.778 300.177 185.317 293.074 195.226 288V318.442C166.687 325.4 134.449 321.341 121.897 318.442Z"
            fill="#213240"
          />
        </g>
        <g className="outer">
          <path
            className="outer-left"
            d="M26.9227 159.44C26.9227 214.189 57.5826 264.943 105.062 285.111V315.427C42.3996 293.924 0 229.276 0 159.44C0 121.408 13.354 86.4897 35.6376 59.0996L53.7489 79.5887C36.9111 101.789 26.9227 129.45 26.9227 159.44Z"
            fill="#90AEC6"
          />
          <path
            className="outer-right"
            d="M210.123 285.111C259.641 265.892 292.303 215.655 292.303 159.44C292.303 129.45 282.315 101.789 265.477 79.5887L283.629 59.1495C305.888 86.5322 319.226 121.432 319.226 159.44C319.226 230.697 274.781 295.069 210.123 315.427V285.111Z"
            fill="#90AEC6"
          />
        </g>
      </svg>
    </div>
  );
};

export default Loader;
