import React, { useContext, useRef } from "react";
// import { Link, Navigate } from 'react-router-dom';
import { UserContext } from "../../context/UserContext";
import TopNav from "./elements/TopNav";
import About from "./elements/About";
import Hero from "./elements/Hero";
import Features from "./elements/Features";
import Contact from "./elements/Contact";
import Footer from "./elements/Footer";

const Landing = () => {
  const { userData } = useContext(UserContext);
  const heroSection = useRef(null);
  const aboutSection = useRef(null);
  const featureSection = useRef(null);
  const contactSection = useRef(null);

  const scrollToSection = (sectionRef) => {
    window.scrollTo({
      top: sectionRef.current.offsetTop - 64,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <TopNav
        user={userData.user}
        scrollToSection={scrollToSection}
        heroSection={heroSection}
        aboutSection={aboutSection}
        featureSection={featureSection}
        contactSection={contactSection}
      />
      <Hero ref={heroSection} />
      <About ref={aboutSection} />
      <Features ref={featureSection} />
      <Contact ref={contactSection} />
      <Footer
        scrollToSection={scrollToSection}
        heroSection={heroSection}
        aboutSection={aboutSection}
        featureSection={featureSection}
        contactSection={contactSection}
      />
    </div>
  );
};

export default Landing;
