import axios from "axios";
import { api } from "../config";

const token = window.localStorage.getItem("auth-token");
const verification_key = window.localStorage.getItem("verification_key");
const reset_token = window.localStorage.getItem("reset_token");

const axiosIntance = axios.create({
  baseURL: api,
  headers: {
    Authorization: token ? `Bearer ${token}` : "",
    "x-auth-token": verification_key
      ? verification_key
      : reset_token
      ? reset_token
      : "",
  },
});

axiosIntance.interceptors.request.use((req) => {
  // const { auth } = store.getState();
  const token = window.localStorage.getItem("auth-token");
  const verification_key = window.localStorage.getItem("verification_key");
  const reset_token = window.localStorage.getItem("reset_token");

  if (token) {
    req.headers.Authorization = `Bearer ${token}`;
  }
  if (verification_key) {
    req.headers["x-auth-token"] = verification_key;
  }

  if (reset_token) {
    req.headers["x-auth-token"] = reset_token;
  }

  return req;
});
export default axiosIntance;
