import React, { forwardRef, useRef, useState } from "react";
import Fade from "react-reveal/Fade";
import emailjs from "@emailjs/browser";
import {
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  Snackbar,
  Alert,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import "./styles/contact.css";
import { Send } from "@mui/icons-material";

const FormTextField = styled((props) => (
  <TextField InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
  "& .MuiFilledInput-root": {
    border: "1px solid #e2e2e1",
    overflow: "hidden",
    borderRadius: 4,
    backgroundColor: "#fff",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
  },
}));

const About = forwardRef((props, ref) => {
  const form = useRef();
  const [snack, setSnack] = useState({
    open: false,
    msg: "",
    error: false,
  });

  const handleClick = (msg, error) => {
    setSnack({ ...snack, open: true, msg: msg, error: error });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnack({ ...snack, open: false, msg: "", severity: null });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        (result) => {
          form.current.reset();
          handleClick("Your mail has been sent successfully.", false);
        },
        (error) => {
          handleClick(error.text, true);
        }
      );
  };

  return (
    <div ref={ref} className="contactSection">
      <Snackbar open={snack.open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={snack.error ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {snack.msg}
        </Alert>
      </Snackbar>
      <img
        src="/assets/landing/contact-bg.jpg"
        alt="Luminox"
        className="bgImgContact"
      />
      <Container className="contactContainer">
        <Grid container spacing={4}>
          <Grid item xs={12} lg={6}></Grid>
          <Grid item xs={12} lg={6}>
            <Fade right cascade delay={300} duration={1200}>
              <Paper
                elevation={4}
                sx={{
                  pl: 4,
                  pr: 4,
                  pt: 4,
                  pb: 6,
                  backgroundColor: "rgb(29,31,33, 0.6)",
                  color: "#fff",
                }}
                className="contactFormContainer"
              >
                <p className="contactTitle">Contact</p>
                <form ref={form} onSubmit={sendEmail}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <FormTextField
                        label="Name"
                        name="from_name"
                        variant="filled"
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormTextField
                        label="Mobile Number"
                        name="mobile_no"
                        variant="filled"
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormTextField
                        multiline
                        rows="4"
                        label="Message"
                        name="message"
                        variant="filled"
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        sx={{ color: "#fff" }}
                        endIcon={<Send />}
                      >
                        Send
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Paper>
            </Fade>
          </Grid>
        </Grid>
        {/* <Fade top cascade></Fade> */}
      </Container>
    </div>
  );
});

export default About;
