import React, { useState } from "react";
import { Button } from "@mui/material";
import { Download } from "@mui/icons-material";

const InstallPrompt = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isInstallable, setIsInstallable] = useState(false);
  const [openFlag, setOpenFlag] = useState(true);
  window.addEventListener("beforeinstallprompt", (e) => {
    e.preventDefault();
    if (
      window.matchMedia("(display-mode: standalone)").matches ||
      window.matchMedia("(max-width: 767px)").matches
    ) {
      setDeferredPrompt(e);
      setIsInstallable(true);
    }
  });

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the install prompt");
        } else {
          console.log("User dismissed the install prompt");
        }
        setDeferredPrompt(null);
      });
    }
    setOpenFlag(false);
  };

  const handleDismissClick = () => {
    setOpenFlag(false);
  };

  return (
    <div>
      {isInstallable&&openFlag && (
        <div
        style={{
          position: "fixed",
          minHeight: "15vh",
          bottom: `${
            (window.matchMedia("(display-mode: standalone)").matches ||
              window.matchMedia("(max-width: 767px)").matches)
              ? "55px"
              : "50vh"
          }`,
          left: 0,
          right: 0,
          backgroundColor: "#fff",
          padding: "16px",
          borderTopLeftRadius: "16px",
          borderTopRightRadius: "16px",
          boxShadow: "0 -2px 4px rgba(0,0,0,.1)",
          animationName: "slide-up",
          animationDuration: ".75s",
          width: `${
            (window.matchMedia("(display-mode: standalone)").matches ||
              window.matchMedia("(max-width: 767px)").matches)
              ? "unset"
              : "16%"
          }`,
          margin: `${
            (window.matchMedia("(display-mode: standalone)").matches ||
              window.matchMedia("(max-width: 767px)").matches)
              ? "unset"
              : "auto"
          }`,
          zIndex: `${
            (window.matchMedia("(display-mode: standalone)").matches ||
              window.matchMedia("(max-width: 767px)").matches)
              ? "unset"
              : "100"
          }`,
          borderBottomLeftRadius: `${
            (window.matchMedia("(display-mode: standalone)").matches ||
              window.matchMedia("(max-width: 767px)").matches)
              ? "unset"
              : "16px"
          }`,
          borderBottomRightRadius: `${
            (window.matchMedia("(display-mode: standalone)").matches ||
              window.matchMedia("(max-width: 767px)").matches)
              ? "unset"
              : "16px"
          }`,
        }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src="assets/logo/maskable_icon.png"
                alt="Logo"
                style={{
                  height: "7.5vh",
                  width: "auto",
                  borderRadius: "50%",
                  marginRight: 16,
                }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  margin: `${
                    (window.matchMedia("(display-mode: standalone)").matches ||
                      window.matchMedia("(max-width: 767px)").matches)
                      ? "unset"
                      : "auto"
                  }`,
                }}
              >
                <h3 style={{ marginBottom: 2, marginTop: 0 }}>
                  Install Luminox
                </h3>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={handleInstallClick}
                  endIcon={<Download />}
                >
                  Install
                </Button>
              </div>
            </div>
            <span
              onClick={handleDismissClick}
              style={{ height: 20, cursor: "pointer", fontWeight: 600 }}
            >
              &#10005;
            </span>
          </div>
        </div>
      )}
      <style>{`
        @keyframes slide-up {
          from { transform: translateY(100%); }
          to { transform: translateY(0); }
        }
      `}</style>
    </div>
  );
};

export default InstallPrompt;
