/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useContext, useEffect, useState } from "react";
import {
  Grid,
  Link,
  Typography,
  Divider,
  Breadcrumbs,
  Box,
  FormControl,
  Select,
  ListSubheader,
  MenuItem,
  CircularProgress,
  Button,
  TextField,
  Snackbar,
  Alert,
  InputAdornment,
  IconButton,
} from "@mui/material";
import styled from "@emotion/styled";
import { UserContext } from "../../context/UserContext";
import { getGroupWiseDevices } from "../../api/group";
import ChartComponent from "./elements/ChartComponent";
import { getInitialMeteringData, syncDeviceEnergy } from "../../api/energy";
import mqtt from "precompiled-mqtt";
import { useNavigate, useParams } from "react-router-dom";
import { fetchDevice } from "../../api/device";
import moment from "moment";
import { CachedOutlined } from "@mui/icons-material";
import ChartFilter from "./elements/ChartFilter";

const PageTitle = styled(Typography)`
  margin: 0px 0px 0.35em;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.25;
`;

const PathLink = styled(Link)`
  margin: 0px;
  color: rgb(55, 111, 208);
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
`;

const ActiveLink = styled(Typography)`
  margin: 0px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
`;

const Metering = ({ socket }) => {
  const [voltageData, setVoltageData] = useState({
    has_phase1: false,
    p1: [],
    p2: [],
    p3: [],
    time: [],
  });
  const [currentData, setCurrentData] = useState({
    p1: [],
    p2: [],
    p3: [],
    time: [],
  });
  const [chartLoading, setChartLoading] = useState(true);
  const [powerData, setPowerData] = useState({
    p1: [],
    p2: [],
    p3: [],
    time: [],
  });
  const navigate = useNavigate();
  const [syncLoading, setSyncLoading] = useState(false);
  const [energy, setEnergy] = useState({
    value: "",
    last_updated_at: "",
  });

  const [deviceData, setDeviceData] = useState([]);
  const [deviceLoad, setDeviceLoad] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { userData } = useContext(UserContext);
  const [deviceId, setDeviceId] = useState();
  const [set, setSet] = useState(false);
  const [phase, setPhase] = useState({
    phase1: false,
    phase2: false,
    phase3: false,
  });
  const [filterOpen, setFilterOpen] = useState(false);
  const [filterData, setFilterData] = useState({
    start_time: "",
    end_time: "",
  });
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [severity, setSeverity] = useState();
  const [time, setTime] = useState();
  const [endTime, setEndTime] = useState();
  const [message, setMessage] = useState("");
  const [initial, setInitial] = useState(true);
  const [startIndex, setStartIndex] = useState(0);

  const { device_id } = useParams();
  useEffect(() => {
    if (device_id) {
      setDeviceId(device_id);
    }
  }, [device_id]);

  useEffect(() => {
    setDeviceLoad(true);
    getGroupWiseDevices(userData.user.company_id._id, setDeviceData, setDeviceLoad);
  }, []);

  useEffect(() => {
    connectClient();
  }, [set]); // Reconnect client when seqCode changes
  const handleFormClose = () => {
    setFilterOpen(false);
  };
  const connectClient = () => {
    const token = window.localStorage.getItem("auth-token");
    const options = {
      keepalive: 30,
      clean: true,
      reconnectPeriod: 500,
      rejectUnauthorized: false,
      username: userData.user.phone_no,
      password: userData.user.token,
    };

    const client = mqtt.connect(
      `wss://${process.env.REACT_APP_MQTT_BROKER_URL}`,
      options
    );

    client.on("connect", function () {
      client.subscribe(`${deviceId}/meteringData`);
      client.subscribe(`${deviceId}/energy`);
    });

    client.on("message", (topic, message) => {
      if (topic === `${deviceId}/meteringData`) {
        // if(filterData.start_time!==""){
        const data = JSON.parse(message.toString());
        let timeset = false;

        if (data.P1 !== undefined) {
          let p1 = voltageData.p1;
          p1.shift();
          p1.push(data.P1.v);
          let time = voltageData.time;
          time.shift();
          time.push(
            new Date().toLocaleString("en-IN", {
              timeZone: "Asia/Kolkata",
              hour12: false,
              timeStyle: "short",
            })
          );
          setVoltageData({ ...voltageData, p1: p1, time: time });

          p1 = currentData.p1;
          p1.shift();
          p1.push(data.P1.i !== undefined ? data.P1.i : "0");
          setCurrentData({ ...currentData, p1: p1, time: time });

          p1 = powerData.p1;
          p1.shift();
          p1.push(data.P1.p !== undefined ? data.P1.p : "0");
          setPowerData({ ...powerData, p1: p1, time: time });
          timeset = true;
        }
        if (data.P2 !== undefined) {
          if (!timeset) {
            let time = voltageData.time;
            time.shift();
            time.push(
              new Date().toLocaleString("en-IN", {
                timeZone: "Asia/Kolkata",
              })
            );
            let p2 = voltageData.p2;
            p2.shift();
            p2.push(data.P2.v);
            setVoltageData({ ...voltageData, p2: p2, time: time });
            p2 = currentData.p2;
            p2.shift();
            p2.push(data.P2.i !== undefined ? data.P2.i : "0");
            setCurrentData({ ...currentData, p2: p2, time: time });

            p2 = powerData.p2;
            p2.shift();
            p2.push(data.P2.p !== undefined ? data.P2.p : "0");
            setPowerData({ ...powerData, p2: p2, time: time });
            timeset = true;
          } else {
            let p2 = voltageData.p2;
            p2.shift();
            p2.push(data.P2.v);
            setVoltageData({ ...voltageData, p2: p2 });
            p2 = currentData.p2;
            p2.shift();
            p2.push(data.P2.i !== undefined ? data.P2.i : "0");
            setCurrentData({ ...currentData, p2: p2 });

            p2 = powerData.p2;
            p2.shift();
            p2.push(data.P2.p !== undefined ? data.P2.p : "0");
            setPowerData({ ...powerData, p2: p2 });
          }
        }
        if (data.P3 !== undefined) {
          if (!timeset) {
            let p3 = voltageData.p3;
            p3.shift();
            p3.push(data.P3.v);
            let time = voltageData.time;
            time.shift();
            time.push(
              new Date().toLocaleString("en-IN", {
                timeZone: "Asia/Kolkata",
              })
            );
            setVoltageData({ ...voltageData, p3: p3, time: time });

            p3 = currentData.p3;
            p3.shift();
            p3.push(data.P3.i !== undefined ? data.P3.i : "0");
            setCurrentData({ ...currentData, p3: p3, time: time });

            p3 = powerData.p3;
            p3.shift();
            p3.push(data.P3.p !== undefined ? data.P3.p : "0");
            setPowerData({ ...powerData, p3: p3, time: time });
            timeset = true;
          } else {
            let p3 = voltageData.p3;
            p3.shift();
            p3.push(data.P3.v);
            setVoltageData({ ...voltageData, p3: p3 });

            p3 = currentData.p3;
            p3.shift();
            p3.push(data.P3.i !== undefined ? data.P3.i : "0");
            setCurrentData({ ...currentData, p3: p3 });

            p3 = powerData.p3;
            p3.shift();
            p3.push(data.P3.p !== undefined ? data.P3.p : "0");
            setPowerData({ ...powerData, p3: p3 });
          }
        }
        // }
      } else if (topic === `${deviceId}/energy` && !initial) {
        const data = JSON.parse(message.toString());
        console.log(data, "dataaa");
        setEnergy({ value: data.data, last_updated_at: new Date() });
      }
    });

    client.on("error", (err) => {
      console.log("Connection error: ", err);
    });
  };

  useEffect(() => {
    if (deviceId !== undefined) {
      getInitialChartData(deviceId, 0);
      navigate(`/dashboard/energy/${deviceId}`);
    }
  }, [deviceId]);
  useEffect(() => {
    if (deviceId !== undefined) {
      getInitialChartData(deviceId, 0);
    }
  }, [refresh]);

  const handleEnergySync = (device_id) => {
    syncDeviceEnergy(
      deviceId,
      setMessage,
      setAlertOpen,
      setSeverity,
      setSyncLoading
    );
  };

  const getInitialChartData = (device_id, loadMore) => {
    getInitialMeteringData(
      device_id,
      true,
      setVoltageData,
      setCurrentData,
      setPowerData,
      setEnergy,
      setChartLoading,
      setSet,
      set,
      setPhase,
      setInitial,
      filterData,
      setTime,
      setEndTime,
      setStartIndex,
      loadMore
    );
  };

  const handleClose = () => {
    setAlertOpen(false);
  };

  const fetchMoreData = (loadMore, start_time) => {
    getInitialMeteringData(
      deviceId,
      true,
      setVoltageData,
      setCurrentData,
      setPowerData,
      setEnergy,
      setChartLoading,
      setSet,
      set,
      setPhase,
      setInitial,
      { start_time: start_time },
      setTime,
      setEndTime,
      setStartIndex,
      loadMore
    );
  }

  return (
    <>
      <PageTitle>Metering</PageTitle>
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{ display: { xs: "none", lg: "block" } }}
      >
        <PathLink underline="hover" href="/">
          Dashboard
        </PathLink>
        <ActiveLink>Metering</ActiveLink>
      </Breadcrumbs>
      <Divider style={{ margin: "16px 0" }} />

      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>

      <Grid container spacing={2} sx={{ mb: 2 }}>
        {!deviceLoad && (
          <>
            <Grid item xs={12} md={6}>
              <FormControl sx={{ minWidth: 120, marginTop: "8px" }} fullWidth>
                <Select
                  defaultValue=""
                  displayEmpty
                  fullWidth
                  id="select-device"
                  value={deviceId ? deviceId : ""}
                  size="small"
                  onChange={(e) => {
                    setDeviceId(e.target.value);
                  }}
                >
                  <MenuItem value="" disabled>
                    Select a device
                  </MenuItem>
                  {deviceData.map((group) => [
                    <ListSubheader key={group.group_name}>
                      {group.group_name}
                    </ListSubheader>,
                    group.device_data.map((device) => (
                      <MenuItem key={device.device_id} value={device.device_id}>
                        {device.device_id}
                      </MenuItem>
                    )),
                  ])}
                </Select>
              </FormControl>
            </Grid>
            {!chartLoading && <Grid item xs={6} md={3}>
              <TextField
                margin="dense"
                fullWidth
                id="energy"
                size="small"
                label="Energy"
                value={energy ? energy.value : ""}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleEnergySync}>
                        <CachedOutlined />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              ></TextField>
            </Grid>}
            {!chartLoading && <Grid item xs={6} md={3}>
              <TextField
                margin="dense"
                fullWidth
                id="energy"
                size="small"
                label="Last Sync Time"
                value={
                  energy && energy.last_updated_at !== ""
                    ? moment(energy?.last_updated_at).calendar()
                    : ""
                }
                inputProps={{
                  readOnly: true,
                }}
              ></TextField>
            </Grid>}
          </>
        )}
      </Grid>

      {!chartLoading && (
        <>
          <Box
            sx={{ ml: 2, p: 1, background: "#f7f9fc", display: "inline-flex" }}
          >
            <Typography color="primary" fontWeight={600}>
              {deviceId}
            </Typography>
          </Box>
          {/* <Box
            sx={{ ml: { xs: 15, lg: 50 }, p: 1, background: "#f7f9fc", display: "inline-flex" }}
          >
            <Typography color="primary" textAlign="center">
              {new Date(time).toLocaleDateString()}
            </Typography>
          </Box> */}
          {/* Chart components here */}
          <Grid
            container
            sx={{
              px: { xs: 0, lg: 1 },
              py: { xs: 2, lg: 3 },
              borderRadius: "10px",
              border: (theme) => `2px solid ${theme.palette.primary.main}`,
              mt: -2.7,
              mb: 5,
            }}
          >
            {phase.phase1 === false &&
              phase.phase2 === false &&
              phase.phase3 === false && (
                <Typography color="error" margin="auto">
                  No Phase Activated for this device.
                </Typography>
              )}
            <Grid item xs={12} lg={12}>

            </Grid>
            {/* Phase 1 */}
            {phase.phase1 === true && (
              <>
                <Grid item xs={12} lg={4}>
                  <ChartComponent
                    data={currentData.p1}
                    time={currentData.time}
                    name={"Current (A)"}
                    color={"red"}
                    setFilterOpen={setFilterOpen}
                    startIndex={startIndex}
                    fetchMoreData={fetchMoreData}
                    start_time={time}
                    end_time={endTime}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <ChartComponent
                    data={voltageData.p1}
                    time={voltageData.time}
                    name={"Voltage (V)"}
                    color={"red"}
                    setFilterOpen={setFilterOpen}
                    startIndex={startIndex}
                    fetchMoreData={fetchMoreData}
                    start_time={time}
                    end_time={endTime}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <ChartComponent
                    data={powerData.p1}
                    time={powerData.time}
                    name={"Power (W)"}
                    color={"red"}
                    setFilterOpen={setFilterOpen}
                    startIndex={startIndex}
                    fetchMoreData={fetchMoreData}
                    start_time={time}
                    end_time={endTime}
                  />
                </Grid>
              </>
            )}

            {/* Phase 2 */}
            {phase.phase2 === true && (
              <>
                <Grid item xs={12} lg={4}>
                  <ChartComponent
                    data={currentData.p2}
                    time={currentData.time}
                    name={"Current (A)"}
                    color={"yellow"}
                    setFilterOpen={setFilterOpen}
                    startIndex={startIndex}
                    fetchMoreData={fetchMoreData}
                    start_time={time}
                    end_time={endTime}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <ChartComponent
                    data={voltageData.p2}
                    time={voltageData.time}
                    name={"Voltage (V)"}
                    color={"yellow"}
                    setFilterOpen={setFilterOpen}
                    startIndex={startIndex}
                    fetchMoreData={fetchMoreData}
                    start_time={time}
                    end_time={endTime}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <ChartComponent
                    data={powerData.p2}
                    time={powerData.time}
                    name={"Power (W)"}
                    color={"yellow"}
                    setFilterOpen={setFilterOpen}
                    startIndex={startIndex}
                    fetchMoreData={fetchMoreData}
                    start_time={time}
                    end_time={endTime}
                  />
                </Grid>
              </>
            )}

            {/* Phase 3 */}
            {phase.phase3 === true && (
              <>
                <Grid item xs={12} lg={4}>
                  <ChartComponent
                    data={currentData.p3}
                    time={currentData.time}
                    name={"Current (A)"}
                    color={"blue"}
                    setFilterOpen={setFilterOpen}
                    startIndex={startIndex}
                    fetchMoreData={fetchMoreData}
                    start_time={time}
                    end_time={endTime}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <ChartComponent
                    data={voltageData.p3}
                    time={voltageData.time}
                    name={"Voltage (V)"}
                    color={"blue"}
                    setFilterOpen={setFilterOpen}
                    startIndex={startIndex}
                    fetchMoreData={fetchMoreData}
                    start_time={time}
                    end_time={endTime}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <ChartComponent
                    data={powerData.p3}
                    time={powerData.time}
                    name={"Power (W)"}
                    color={"blue"}
                    setFilterOpen={setFilterOpen}
                    startIndex={startIndex}
                    fetchMoreData={fetchMoreData}
                    start_time={time}
                    end_time={endTime}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </>
      )}
      {/* </Paper> */}
      <ChartFilter
        filterOpen={filterOpen}
        handleFilterClose={handleFormClose}
        refresh={refresh}
        setRefresh={setRefresh}
        filterData={filterData}
        setFilterData={setFilterData}
      />
    </>
  );
};

export default Metering;
