import React, { forwardRef } from "react";
import { Container, Typography } from "@mui/material";
import Fade from "react-reveal/Fade";
import "./styles/about.css";

const About = forwardRef((props, ref) => {
  return (
    <div ref={ref} className="aboutSection">
      <img src="/assets/logo/icon-png.png" alt="Luminox" className="bgImg" />
      <Container className="aboutContainer">
        <Fade top cascade delay={500}>
          <p className="aboutTitle">About</p>
        </Fade>
        <Fade delay={600}>
          <Typography variant="h5">
            Luminox, the <b>Next-Gen</b> smart street light controller, is an
            IoT based device capable of controlling and managing street lights
            from anywhere around the world with a single click. Not only this,
            Luminox's advanced inbuilt algorithm detects and alerts faulty
            street lights making their maintainence process even smoother.{" "}
            <br />
            It is one of the core innovations of KasperTech, an urban automation
            startup working towards creating smart technologies for a safer and
            smarter future ahead. With Luminox, our mission is to automate the
            control, management and maintainence of the street lightning system
            thus, reducing their carbon footprints, and contributing towards a
            smarter future for us and generations to come.
          </Typography>
        </Fade>
      </Container>
    </div>
  );
});

export default About;
