import React, { useState, useEffect } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  InputBase,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Tooltip,
  Button,
  MenuItem,
  Chip,
} from "@mui/material";
import { Search as SearchIcon, AddBox } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { fetchAllGroups } from "../../../api/group";
import { fetchAllRoles } from "../../../api/role";
import { registerUser, updateUser } from "../../../api/users";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#f0f0f0",
  "&:hover": {
    backgroundColor: "#f7f9fc",
  },
  marginLeft: 0,
  marginRight: 10,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const headers = [
  { id: "name", label: "Name" },
  { id: "phone", label: "Phone No." },
  { id: "groups", label: "Groups" },
  { id: "role", label: "Role" },
  { id: "created_by", label: "Created by" },
  { id: "updated_at", label: "Last Updated At" },
  // { id: "updated_by", label: "Last Updated By" },
];

const UsersTable = ({
  permission,
  usData,
  user,
  reload,
  total,
  setReload,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  setAlertOpen,
  setMessage,
  setSeverity,
  searchQuery,
  handleSearchChange
}) => {
  const [formOpen, setFormOpen] = useState(false);
  const [update, setUpdate] = useState(false);
  const [userId, setUserId] = useState(null);
  const [groupData, setGroupData] = useState([]);
  const [grpLoading, setGrpLoading] = useState(false);
  const [roleData, setRoleData] = useState([]);
  const [roleLoading, setRoleLoading] = useState(false);
  const [userData, setUserData] = useState({
    full_name: "",
    phone_no: "",
    password: "",
    role_id: null,
  });
  const [formState, setFormState] = React.useState({
    groupArray: [],
  });

  const handleFieldChange = (event) => {
    setFormState((formState) => ({
      ...formState,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    }));
  };

  useEffect(() => {
    if (permission.includes("FETCH_GROUP")) {
      fetchAllGroups(user.company_id._id,setGroupData, setGrpLoading, "false");
    } else {
      setGrpLoading(false);
    }
    if (permission.includes("FETCH_ROLES")) {
      fetchAllRoles(user.company_id._id,setRoleData, setRoleLoading);
    } else {
      setRoleLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFormOpen = () => {
    setFormOpen(true);
  };

  const handleFormClose = () => {
    setUserId();
    setUserData({
      full_name: "",
      phone_no: "",
      password: "",
      role_id: null,
    });
    setFormOpen(false);
    setUpdate(false);
    setReload(!reload);
    setFormState({ groupArray: [] });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSubmit = () => {
    const data = {
      ...userData,
      group_array: formState.groupArray,
      company_id:user.company_id._id
    };
    registerUser(data,user.company_id._id, setSeverity, setMessage, setAlertOpen, handleFormClose);
  };

  const handleUpdate = () => {
    const data = {
      ...userData,
      group_array: formState.groupArray,
      company_id:user.company_id._id
    };
    updateUser(
      userId,
      data,
      setSeverity,
      setMessage,
      setAlertOpen,
      handleFormClose
    );
  };

  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <div
          style={{
            margin: "16px 8px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" sx={{ ml: 1 }}>
            All Users
          </Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
          <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search Users"
                inputProps={{ "aria-label": "search" }}
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </Search>
            {permission.includes("CREATE_USER") && (
              <IconButton>
                <Tooltip
                  title={
                    grpLoading || roleLoading
                      ? "Loading Group and Roles"
                      : "Add User"
                  }
                  arrow
                  placement="top"
                >
                  <AddBox
                    onClick={handleFormOpen}
                    disabled={grpLoading || roleLoading}
                  />
                </Tooltip>
              </IconButton>
            )}
          </div>
        </div>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {headers.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}

                {permission.includes("UPDATE_USER") && (
                  <TableCell align="center">Action</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {usData.map((row) => (
                <TableRow key={row.phone_no}>
                  <TableCell>{row.full_name}</TableCell>
                  <TableCell>{row.phone_no}</TableCell>
                  {row.groupData.length === 0 ? (
                    <TableCell>--</TableCell>
                  ) : (
                    <TableCell>
                      {row.groupData.map((dt) => {
                        return (
                          <Chip
                            variant="outlined"
                            label={dt.group_name}
                            sx={{ margin: "2px" }}
                          />
                        );
                      })}
                    </TableCell>
                  )}
                  {row.roleData === undefined ? (
                    <TableCell>--- </TableCell>
                  ) : (
                    <TableCell>{row.roleData?.role_name}</TableCell>
                  )}
                  <TableCell>{row.created_by}</TableCell>
                  <TableCell>
                    {new Date(row.updatedAt).toLocaleString("en-IN", {
                      timeZone: "Asia/Kolkata",
                    })}
                  </TableCell>
                  {/* <TableCell>
                    {row.last_updated_by === "" ? "--" : row.last_updated_by}
                  </TableCell> */}
                  {permission.includes("UPDATE_USER") && (
                    <TableCell align="center">
                      <Button
                        color="secondary"
                        variant="outlined"
                        onClick={() => {
                          setUserData({
                            ...userData,

                            role_id:
                              row.roleData === undefined
                                ? null
                                : row.roleData._id,
                            phone_no: row.phone_no,
                            full_name: row.full_name,
                          });
                          setFormState({ groupArray: row.group_array });
                          setUpdate(true);
                          setUserId(row._id);
                          setFormOpen(true);
                        }}
                        disabled={
                          user.phone_no === row.phone_no ||
                          grpLoading ||
                          roleLoading
                        }
                      >
                        {grpLoading || roleLoading
                          ? "Loading Groups and Roles"
                          : "Update"}
                      </Button>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      {/* Create/Update User Form Modal */}
      <Dialog open={formOpen} onClose={handleFormClose}>
        {update ? (
          <DialogTitle>Update User</DialogTitle>
        ) : (
          <DialogTitle>Create new User</DialogTitle>
        )}
        <DialogContent>
          <TextField
            margin="normal"
            required
            fullWidth
            id="fullname"
            label="Full Name"
            name="fullname"
            value={userData.full_name}
            autoFocus
            onChange={(e) => {
              setUserData({ ...userData, full_name: e.target.value });
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="phone_no"
            label="Phone Number"
            name="Phone Number"
            value={userData.phone_no}
            disabled={update}
            autoFocus
            onChange={(e) => {
              setUserData({ ...userData, phone_no: e.target.value });
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            value={userData.password}
            name="password"
            label="Password"
            type="password"
            id="password"
            onChange={(e) => {
              setUserData({ ...userData, password: e.target.value });
            }}
          />
          {permission.includes("FETCH_GROUP") && (
            <>
              {!grpLoading && (
                <TextField
                  select
                  margin="normal"
                  fullWidth
                  label="Group Name"
                  name="groupArray"
                  id="groupArray"
                  SelectProps={{
                    multiple: true,
                    value: formState.groupArray,
                    onChange: handleFieldChange,
                  }}
                >
                  {groupData.map((option) => (
                    <MenuItem key={option._id} value={option._id}>
                      {option.group_name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </>
          )}

          {permission.includes("FETCH_ROLES") && (
            <>
              {!roleLoading && (
                <TextField
                  select
                  margin="normal"
                  fullWidth
                  label="Role Name"
                  value={userData.role_id}
                  onChange={(e) => {
                    setUserData({ ...userData, role_id: e.target.value });
                  }}
                >
                  <MenuItem value={null} style={{ color: "red" }}>
                    Remove user's Role
                  </MenuItem>
                  {roleData.map((option) => (
                    <MenuItem key={option._id} value={option._id}>
                      {option.role_name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          {update ? (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ margin: "0px 16px 16px 16px", color: "#fff" }}
              onClick={handleUpdate}
            >
              Update User
            </Button>
          ) : (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ margin: "0px 16px 16px 16px", color: "#fff" }}
              onClick={handleSubmit}
            >
              Create User
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UsersTable;
