import axiosIntance from "../helper/axios";
import axios from "axios";
import { api } from "../config";

const subscribeNotification = (data) => {
  axiosIntance
    .post(`/user/notification/subscribe`, data)
    .then((res) => {
      // console.log(res);
    })
    .catch((err) => {
      console.log(err.response.data.error);
    });
};

const fetchNotifications = (user_id,company_id, setNotifications, setLoading) => {
  setLoading(true);
  axiosIntance
    .get(`/user/notification/all/${user_id}/${company_id}?page=1&limit=20`)
    .then((res) => {
      setNotifications(res.data.results);
      marknotifications(user_id,company_id);
      setLoading(false);
    })
    .catch((err) => {
      console.log(err.response.data.error);
    });
};
const fetchEnergyNotifications = (user_id, company_id,setNotifications, setLoading) => {
  setLoading(true);
  axiosIntance
    .get(`/user/notification/energy/all/${user_id}/${company_id}?page=1&limit=100`)
    .then((res) => {
      setNotifications(res.data.results);
      markEnergynotifications(user_id,company_id);
      setLoading(false);
    })
    .catch((err) => {
      // console.log(err.response.data.error);
    });
};

const marknotifications = (user_id,company_id) => {
  axiosIntance
    .put(`/user/notification/mark/${user_id}/${company_id}`)
    .then((res) => {})
    .catch((err) => {
      // console.log(err.response.data.error);
    });
};
const markEnergynotifications = (user_id,company_id) => {
  axiosIntance
    .put(`/user/notification/energy/mark/${user_id}/${company_id}`)
    .then((res) => {})
    .catch((err) => {
      // console.log(err.response.data.error);
    });
};

const removeNotificationToken = (user_id, notification_token, auth_token) => {
  const data = {
    notification_token: notification_token,
  };
  axios
    .put(`${api}/user/notification/remove/token/${user_id}`, data, {
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    })
    .then((res) => {})
    .catch((err) => {
      // console.log(err);
    });
};

const changeNotificationSetting = (
  data,
  setdisabled,
  setdisabledmessage,
  setSwitchLoading,
  setAlertOpen,
  setSeverity,
  setMessage,
  setting_name,
  company_id
) => {
  axiosIntance
    .post(`/setting/notification/update/${company_id}`, data)
    .then((res) => {
      setAlertOpen(true);
      setSeverity("success");
      setMessage(res.data.message);
      setSwitchLoading((prev) => {
        const updatedArray = prev.filter((item) => item !== setting_name);
        return updatedArray;
      });
      setdisabled((prev) => {
        const updatedArray = prev.filter((item) => item !== setting_name);
        return updatedArray;
      });
      setdisabledmessage("");
    })
    .catch((err) => {
      setAlertOpen(true);
      setSeverity("error");
      // console.log(err);
      setMessage(err.response.data.error);
      setSwitchLoading((prev) => {
        const updatedArray = prev.filter((item) => item !== setting_name);
        return updatedArray;
      });
      setdisabled((prev) => {
        const updatedArray = prev.filter((item) => item !== setting_name);
        return updatedArray;
      });
      setdisabledmessage("");
    });
};
export {
  subscribeNotification,
  fetchNotifications,
  fetchEnergyNotifications,
  marknotifications,
  removeNotificationToken,
  changeNotificationSetting,
};
