// Log filters

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React from "react";


export default function LogFilter({
  filterOpen,
  handleFilterClose,
  setFormState,
  formState,
  refresh,
  setRefresh,
  filterData,
  setFilterData,
}) {
  // const handleFieldChange = (event) => {
  //   setFormState((formState) => ({
  //     ...formState,
  //     [event.target.name]:
  //       event.target.type === "checkbox"
  //         ? event.target.checked
  //         : event.target.value,
  //   }));
  // };

  return (
    <>
      <Dialog
        open={filterOpen}
        onClose={handleFilterClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Filter Logs"}</DialogTitle>
        <DialogContent>
          <TextField
            type="datetime-local"
            label="Start Time"
            name="start_time"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            value={filterData.start_time}
            onChange={(e) => {
              setFilterData({ ...filterData, start_time: e.target.value });
            }}
          />
          <TextField
            type="datetime-local"
            label="End Time"
            name="end_time"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            value={filterData.end_time}
            onChange={(e) => {
              setFilterData({ ...filterData, end_time: e.target.value });
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="outlined"
            onClick={(e) => {
              setFormState({ ...formState, action: [] });
              setFilterData({ end_time: "", start_time: "" });
              setRefresh(!refresh);
              handleFilterClose();
            }}
          >
            Reset Filter
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={(e) => {
              setRefresh(!refresh);
              handleFilterClose();
            }}
          >
            Set filter
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
