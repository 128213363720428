/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Avatar,
  Typography,
  Divider,
  Button,
  Snackbar,
  Alert,
  Badge,
  CircularProgress,
} from "@mui/material";

import Toggle from "./elements/Toggle";
import Mode from "./elements/Mode";
import Timer from "./elements/Timer";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { getInitialMeteringData } from "../../../../api/energy";
import { UserContext } from "../../../../context/UserContext";
import TimerDialog from "./elements/TimerDialog";

const Switch = ({ client, Id, companyId, loading, reconnect, device_data }) => {
  const navigate = useNavigate();
  const { userData } = useContext(UserContext);

  const [active, setActive] = React.useState(false);
  const [switchData, setswitchData] = React.useState();
  const [switchStatus, setswitchStatus] = React.useState();
  const [modeData, setmodeData] = React.useState();
  const [modeStatus, setmodeStatus] = React.useState();
  const [block, setBlock] = React.useState(false);
  const [blockMessage, setBlockMessage] = useState("");
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [severity, setSeverity] = useState();
  const [message, setMessage] = useState("");
  const [tooltip, setToolTip] = useState({
    st: 0,
    et: 0,
  });
  const [tooltip2, setToolTip2] = useState({
    st: 0,
    et: 0,
  });
  const [timerLoad, setTimerLoad] = useState(false);
  const [clearLoad, setClearLoad] = useState(false);
  const [initialization, setInitialization] = useState(false);

  const [timeData, settimeData] = React.useState({
    st: "9999",
    et: "9999",
  });
  const [timeStatus, settimeStatus] = React.useState({
    st: "9999",
    et: "9999",
  });
  const [timeData2, settimeData2] = React.useState({
    st: "9999",
    et: "9999",
  });
  const [timeStatus2, settimeStatus2] = React.useState({
    st: "9999",
    et: "9999",
  });
  const [initial, setInitial] = useState(true);
  const [switchLoading, setSwitchLoading] = useState(false);
  const [disabled, setdisabled] = useState(false);
  const [disabledmessage, setdisabledmessage] = useState("");
  const [prevstate, setPrevState] = useState(device_data.status)
  const [meteringData, setMeteringData] = useState({});

  const [timerDialogOpen, setTimerDialogOpen] = useState(false);
  const handleTimerDialogClose = () => {
    setTimerDialogOpen(false);
  }

  React.useEffect(() => {
    client.subscribe(`${Id}/#`, {
      qos: 1,
    });
  }, [client]);

  React.useEffect(() => {
    // if (device_data.has_metering) {
    getInitialMeteringData(Id, false, setMeteringData, "", "", "", "");
    // }
  }, []);

  const checkState = (st, et) => {
    if (!initial) {
      setSeverity("success");
      setAlertOpen(true);
      if (st === "9999" && et === "9999") {
        setMessage("Timer cleared successfully");
      } else {
        setMessage(`Timer set for ${st} and ${et}`);
      }
    }
  };

  React.useEffect(() => {
    if (reconnect || loading) {
      setdisabled(true);
      setdisabledmessage(
        `Device is ${reconnect ? "Reconnecting" : "Initialising"
        }... Please wait`
      );
    } else {
      setdisabled(false);
      setdisabledmessage("");
    }
  }, [reconnect, loading]);

  useEffect(() => {
    if (device_data.status === "offline") {
      setdisabled(false);
      setdisabledmessage(
        "Your Operation will be Performed Once device comes Online."
      );
      setActive("offline");
    }
  }, []);

  React.useEffect(() => {
    client.on("message", function (topic, message) {
      if (topic === `${Id}/status`) {
        var packet = JSON.parse(message.toString());
        setActive((prevState) => {
          setPrevState(prevState); // Set the previous state first
          return packet.data; // Then update the active state with the new data
        });
        if (packet.data === "offline" || packet.data === "false") {
          setSwitchLoading(false);
          setdisabled(false);
          setdisabledmessage(
            "Your Operation will be Performed Once device comes Online."
          );
        }
        if (packet.data === "starting" || packet.data === "updating") {
          setSwitchLoading(false);
          setdisabled(true);
          setdisabledmessage(`Device is ${packet.data}... Please wait`);
        }
        if (packet.data === "online") {
          // console.log(prevstate);
          // console.log(packet.data);
          if (prevstate === "offline" || prevstate === false) {
            console.log("here");
            setInitialization(true);
            setdisabled(true);
            setdisabledmessage("Device is Initialising... Please wait");
            setTimeout(() => {
              setInitialization(false);
              setdisabled(false);
              setdisabledmessage("");
            }, 10000);
          } else {
            setdisabled(false);
            setdisabledmessage("");
          }

        }
      }

      if (topic === `${Id}/switch`) {
        var packet = JSON.parse(message.toString());
        setswitchData(packet.data);
      }

      if (topic === `${Id}/switchStatus`) {
        var packet = JSON.parse(message.toString());
        setswitchStatus(packet.data);
        setSwitchLoading(false);
        setdisabled(false);
        setdisabledmessage("");
      }

      if (topic === `${Id}/mode`) {
        var packet = JSON.parse(message.toString());
        setmodeData(packet.data);
        setdisabled(false);
        setdisabledmessage("");
      }

      if (topic === `${Id}/blocked`) {
        var packet = JSON.parse(message.toString());
        setBlock(packet.data === "1" ? true : false);
        setdisabled(packet.data === "1" ? true : false);
        setdisabledmessage(
          packet.data === "1" ? "Device is blocked. Cannot perform Action" : ""
        );
      }

      if (topic === `${Id}/modeStatus`) {
        var packet = JSON.parse(message.toString());
        setmodeStatus(packet.data);
      }

      if (topic === `${Id}/timer`) {
        var packet = JSON.parse(message.toString());
        let temp = packet.data.split(",");
        let st = temp[0];
        let et = temp[1];

        if (st !== "9999") {
          st = st[0] + st[1] + ":" + st[2] + st[3];
        }
        if (et !== "9999") {
          et = et[0] + et[1] + ":" + et[2] + et[3];
        }
        settimeData({ st: st, et: et });
      }

      if (topic === `${Id}/timerStatus`) {
        var packet = JSON.parse(message.toString());
        let temp = packet.data.split(",");
        let st = temp[0];
        let et = temp[1];

        if (st !== "9999") {
          st = st[0] + st[1] + ":" + st[2] + st[3];
        }
        if (et !== "9999") {
          et = et[0] + et[1] + ":" + et[2] + et[3];
        }
        settimeStatus({ st: st, et: et });
        setTimerLoad(false);
        setClearLoad(false);
        handleTimerDialogClose();
        checkState(st, et);
        setdisabled(false);
        setdisabledmessage("");
      }
      if (topic === `${Id}/timer2`) {
        var packet = JSON.parse(message.toString());
        let temp = packet.data.split(",");
        let st = temp[0];
        let et = temp[1];

        if (st !== "9999") {
          st = st[0] + st[1] + ":" + st[2] + st[3];
        }
        if (et !== "9999") {
          et = et[0] + et[1] + ":" + et[2] + et[3];
        }
        settimeData2({ st: st, et: et });
      }

      if (topic === `${Id}/timerStatus2`) {
        var packet = JSON.parse(message.toString());
        let temp = packet.data.split(",");
        let st = temp[0];
        let et = temp[1];

        if (st !== "9999") {
          st = st[0] + st[1] + ":" + st[2] + st[3];
        }
        if (et !== "9999") {
          et = et[0] + et[1] + ":" + et[2] + et[3];
        }
        settimeStatus2({ st: st, et: et });
        setTimerLoad(false);
        setClearLoad(false);
        checkState(st, et);
        setdisabled(false);
        handleTimerDialogClose();
        setdisabledmessage("");
      }
      if (topic === `${Id}/blockInfo`) {
        var packet = JSON.parse(message.toString());
        setBlockMessage(packet.data);
      }
      if (topic === `${Id}/meteringData`) {
        var packet = JSON.parse(message.toString());
        // console.log(packet, switchData, "switchData");
        if (switchData === "1") {
          let obj = {};
          if (packet.P1 !== undefined) {
            obj = { ...obj, phase1: { power: packet.P1.p } };
          }
          if (packet.P3 !== undefined) {
            obj = { ...obj, phase3: { power: packet.P3.p } };
          }
          if (packet.P2 !== undefined) {
            obj = { ...obj, phase2: { power: packet.P2.p } };
          }
          // console.log(obj, "obje");
          setMeteringData([obj]);
        }
      }
    });
  }, [client, initial]);

  const handleTime = (e) => {
    setInitial(false);
    setdisabled(true);
    setdisabledmessage(
      "An Operation is going on. Please wait to perform another action."
    );
    if (timeData.st === "9999" || timeData.et === "9999") {
      setAlertOpen(true);
      setSeverity("error");
      setMessage("Please enter both on time and off time");
      setdisabled(false);
      setdisabledmessage("");
      return;
    }
    if (active === "offline") {
      showmodal("Your Operation will be Performed Once device comes Online.");
    }
    setTimerLoad(true);
    let time_final =
      timeData.st.replace(":", "") + "," + timeData.et.replace(":", "");
    const token = window.localStorage.getItem("auth-token");

    let publish_data = {
      data: time_final,
      token: userData.user.token,
      // metaData: "Timer by App",
    };
    var options = {
      retain: true,
      qos: 1,
    };

    client.publish(`${Id}/timer`, JSON.stringify(publish_data), options);
    setToolTip({ st: "0", et: "0" });
  };

  const clearTime = async (req, res) => {
    if (timeData2.st !== "9999" && timeData2.et !== "9999") {
      handleTimerDialogClose();
      showmodal("Please Clear Timer-2 first");
      return;
    }
    const token = window.localStorage.getItem("auth-token");
    setdisabled(true);
    setdisabledmessage(
      "An Operation is going on. Please wait to perform another action."
    );
    setClearLoad(true);
    let publish_data = {
      data: "9999,9999",
      token: userData.user.token,
    };
    var options = {
      retain: true,
      qos: 1,
    };

    client.publish(`${Id}/timer`, JSON.stringify(publish_data), options);
  };
  const handleTime2 = (e) => {
    setInitial(false);
    setdisabled(true);
    setdisabledmessage(
      "An Operation is going on. Please wait to perform another action."
    );
    if (timeData2.st === "9999" || timeData2.et === "9999") {
      setAlertOpen(true);
      setSeverity("error");
      setMessage("Please enter both on time and off time");
      setdisabled(false);
      setdisabledmessage("");
      return;
    }
    if (active === "offline") {
      showmodal("Your Operation will be Performed Once device comes Online.");
    }
    setTimerLoad(true);
    let time_final =
      timeData2.st.replace(":", "") + "," + timeData2.et.replace(":", "");
    const token = window.localStorage.getItem("auth-token");

    let publish_data = {
      data: time_final,
      token: userData.user.token,
      // metaData: "Timer by App",
    };
    var options = {
      retain: true,
      qos: 1,
    };

    client.publish(`${Id}/timer2`, JSON.stringify(publish_data), options);
    setToolTip2({ st: "0", et: "0" });
  };

  const clearTime2 = async (req, res) => {
    const token = window.localStorage.getItem("auth-token");
    setdisabled(true);
    setdisabledmessage(
      "An Operation is going on. Please wait to perform another action."
    );
    let publish_data = {
      data: "9999,9999",
      token: userData.user.token,
    };
    setClearLoad(true);
    var options = {
      retain: true,
      qos: 1,
    };

    client.publish(`${Id}/timer2`, JSON.stringify(publish_data), options);
  };

  const handleClose = () => {
    setAlertOpen(false);
  };
  const showmodal = (message) => {
    handleTimerDialogClose();
    Swal.fire({
      title: "Action Alert",
      text: `${message}`,
      icon: "warning",
      showConfirmButton: true,
      confirmButtonText: "Okay",
      confirmButtonColor: "#213240",
    }).then((result) => {
      setAlertOpen(false);
    });
  };
  return (
    <>
      <Box>
        <Snackbar
          open={alertOpen}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
            {message}
          </Alert>
        </Snackbar>
        <Card
          variant="outlined"
          style={{ maxWidth: 280, paddingBottom: 8 }}
          onClick={(e) => {
            if (e.target.innerText !== "METERING") {
              if (disabled) {
                showmodal(disabledmessage);
              }
              else if (initialization) {
                showmodal("Device is Initialising... Please wait")
              }
            }
          }}
        >
          <CardContent style={{ paddingBottom: 8 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5">
                  {device_data.device_description}

                  <Avatar
                    style={
                      active === "online"
                        ? {
                          backgroundColor: "#3cb043",
                          height: 10,
                          width: 10,
                          float: "right",
                          marginTop: 11,
                          boxShadow: "0 0 10px 1px green",
                        }
                        : active === "updating"
                          ? {
                            backgroundColor: "#FFC300",
                            height: 10,
                            width: 10,
                            float: "right",
                            marginTop: 11,
                            boxShadow: "0 0 10px 1px yellow",
                          }
                          : active === "starting"
                            ? {
                              backgroundColor: "#7FB3D5",
                              height: 10,
                              width: 10,
                              float: "right",
                              marginTop: 11,
                              boxShadow: "0 0 10px 1px #2980B9",
                            }
                            : {
                              backgroundColor: "#d0312d",
                              height: 10,
                              width: 10,
                              float: "right",
                              marginTop: 11,
                              boxShadow: "0 0 10px 1px red",
                            }
                    }
                  >
                    &nbsp;
                  </Avatar>
                </Typography>
                <Typography variant="caption">ID - {Id}</Typography>

                <div>
                  {reconnect && (
                    <Typography variant="caption" color="textSecondary">
                      Reconnecting device...
                    </Typography>
                  )}
                  {loading && (
                    <Typography variant="caption" color="textSecondary">
                      Initialising device...
                    </Typography>
                  )}
                  {block && (
                    <Typography variant="caption" color="error">
                      {blockMessage + "!!"}
                    </Typography>
                  )}

                  {/* 
                {device_data.has_metering === true && (
                  <Typography variant="caption" color="textSecondary">
                    Defined:{device_data.phase1.totalPower}W Current:
                    {meteringData[0]?.phase1 !== undefined &&
                      meteringData[0].phase1.power}
                    W
                  </Typography>
                )} */}
                </div>

                <Divider style={{ marginTop: 16 }} />
              </Grid>
              <Grid item xs={5} id={Id}>
                <svg
                  id={Id}
                  width="90"
                  height="220"
                  viewBox="0 0 90 180"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0)">
                    <path
                      d="M2.05852 180C0.434524 179.011 -0.0833979 177.513 0.00877468 175.685C0.0702231 174.459 0.00877468 173.224 0.00877468 171.994C0.0438881 166.18 2.90124 162.928 9.02853 161.887V111.128H32.8793V161.878C33.6693 162.01 34.2838 162.084 34.8895 162.221C39.1119 163.201 41.8244 166.646 41.9956 171.29C42.0483 172.754 41.9517 174.221 42.0219 175.685C42.1053 177.513 41.583 179.007 39.9678 180H2.05852ZM6.11412 173.901H35.9254C35.9808 172.83 35.972 171.758 35.899 170.688C35.6928 168.997 34.613 168.052 32.932 168.052C24.9876 168.02 17.0417 168.02 9.09436 168.052C7.40892 168.052 6.34674 169.023 6.13606 170.688C6.06414 171.758 6.05682 172.83 6.11412 173.901V173.901Z"
                      fill="#7F8C8D"
                    />
                    <path
                      d="M32.9539 104.941H9.22606C9.22606 101.46 9.10755 98.0284 9.30506 94.614C9.35773 93.6385 10.1434 92.5004 10.9203 91.8149C11.8464 91.0019 12.0263 90.1714 12.022 89.0684C12.022 76.9445 12.0571 64.8162 11.9868 52.6923C11.9605 48.3595 13.2202 44.9011 17.2977 42.8138C17.7367 42.5941 17.9781 41.6625 17.9825 41.0561C18.0293 33.6736 18.0366 26.2927 18.0044 18.9131C17.9781 12.6512 20.2692 7.51428 25.2509 3.66925C28.102 1.36926 31.641 0.0927898 35.3021 0.0439432C50.5179 -0.0146477 65.7337 -0.0146477 80.9495 0.0439432C86.0673 0.0659148 89.7279 3.84063 89.9078 8.96001C89.943 9.88721 89.9078 10.8188 89.9078 11.8647H54.0483V5.99824H49.3431C45.1295 5.99824 40.9203 5.99824 36.7067 5.99824C29.3197 6.04658 24.2458 10.7924 24.0746 18.1661C23.8947 25.8957 24.0176 33.6297 24.0746 41.3637C24.088 41.658 24.1661 41.9457 24.3034 42.2063C24.4407 42.4669 24.6339 42.6938 24.8691 42.871C28.5999 44.8528 30.0658 48.0211 30.0571 52.099C30.022 64.2845 30.0571 76.4655 30.0263 88.6509C30.0263 89.9956 30.2809 90.9887 31.3431 92.0258C32.2459 93.0085 32.7971 94.2636 32.91 95.594C33.1032 98.6436 32.9539 101.693 32.9539 104.941Z"
                      fill="#BDC3C7"
                    />
                    <path
                      d="M54.0132 18.0563H89.5962C91.308 23.7293 87.3753 29.688 81.5596 29.8814C75.3094 30.0879 69.0461 30.0527 62.7915 29.9253C57.7396 29.8242 54.1756 26.0891 54.0132 21.0532C53.9868 20.1304 54.0132 19.1856 54.0132 18.0563Z"
                      fill={switchStatus === "1" ? "#FFE227" : "#F0F0F0"}
                    />
                  </g>
                  <defs>
                    <clipPath>
                      <rect width="90" height="180" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </Grid>

            <Grid item xs={7}>
              <Grid container spacing={2}>
              {device_data?.feature_data?.some((feature)=>feature.feature_code==="TOGGLE") && (<Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    padding: 0,
                  }}
                >
                  <Toggle
                    Id={Id}
                    disabled={disabled}
                    setdisabled={setdisabled}
                    setdisabledmessage={setdisabledmessage}
                    block={block}
                    client={client}
                    state={switchData}
                    mode={modeData}
                    modeStatus={modeStatus}
                    companyId={companyId}
                    switchLoading={switchLoading}
                    setSwitchLoading={setSwitchLoading}
                    showmodal={showmodal}
                    active={active}
                    initialization={initialization}
                  />
                </Grid>)}
                {device_data?.feature_data?.some((feature)=>feature.feature_code==="SHOW_AUTO") && (<Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  
                    <Mode
                      Id={Id}
                      auto={modeData}
                      block={block}
                      modeStatus={modeStatus}
                      client={client}
                      companyId={companyId}
                      disabled={disabled}
                      setdisabled={setdisabled}
                      setdisabledmessage={setdisabledmessage}
                      active={active}
                      setSeverity={setSeverity}
                      setMessage={setMessage}
                      setAlertOpen={setAlertOpen}
                      showmodal={showmodal}
                      initialization={initialization}
                    />
                  </Grid>)}
                  <Grid item xs={12}>
                    <Timer
                      disabled={disabled}
                      setdisabled={setdisabled}
                      disabledmessage={disabledmessage}
                      timer={timeData}
                      setTimer={settimeData}
                      timeStatus={timeStatus}
                      tooltip={tooltip}
                      setToolTip={setToolTip}
                      initialization={initialization}
                      setTimerDialogOpen={setTimerDialogOpen}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {/* <Badge
                    color="success"
                    badgeContent="click"
                    anchorOrigin={{
                      horizontal: "left",
                      vertical: "top",
                    }}
                    invisible={
                      !(
                        (tooltip.st === "1" && timeData.st !== "9999") ||
                        (tooltip.et === "1" && timeData.et !== "9999")
                      )
                    }
                  > */}
                    <Button
                      variant="contained"
                      style={
                        modeData === "0" || modeStatus === "0" || loading
                          ? { background: "#e0e0e0", color: "#808080" }
                          : {
                            background: "#90aec6",
                            color: "#fff",
                            fontWeight: 600,
                          }
                      }
                      onClick={() => setTimerDialogOpen(true)}
                      fullWidth
                      size="small"
                      disabled={
                        modeData === "0" ||
                        modeStatus === "0" ||
                        loading ||
                        block ||
                        disabled ||
                        initialization
                      }
                    >
                      {/* {timerLoad ? (
                        <CircularProgress
                          style={{ color: "white" }}
                          size={21}
                        />
                      ) : ( */}
                      Update Timer
                      {/* )} */}
                    </Button>
                    {/* </Badge> */}
                  </Grid>
                  {/* <Grid item xs={6}>
                  <Button
                    variant="contained"
                    style={
                      modeData === "0" || modeStatus === "0" || loading
                        ? { background: "#e0e0e0", color: "#808080" }
                        : {
                            background: "#90aec6",
                            color: "#fff",
                            fontWeight: 600,
                          }
                    }
                    size="small"
                    disabled={
                      modeData === "0" ||
                      modeStatus === "0" ||
                      loading ||
                      block ||
                      disabled||
                      initialization
                    }
                    onClick={clearTime}
                  >
                    Clear
                  </Button>
                </Grid> */}
                {device_data.has_metering === true &&device_data?.feature_data?.some((feature)=>feature.feature_code==="METERING") && (
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      size="small"
                      fullWidth
                      style={{ color: "#fff" }}
                      onClick={(e) => {
                        navigate(`/dashboard/energy/${Id}`);
                      }}
                    >
                      metering
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
    {timerDialogOpen&& <TimerDialog open={timerDialogOpen} onClose={handleTimerDialogClose}
    disabled={disabled}
    setdisabled={setdisabled}
    disabledmessage={disabledmessage}
    timer={timeData}
    timer2={timeData2}
    setTimer={settimeData}
    setTimer2={settimeData2}
    timeStatus={timeStatus}
    timeStatus2={timeStatus2}
    tooltip={tooltip}
    tooltip2={tooltip2}
    setToolTip={setToolTip}
    setToolTip2={setToolTip2}
    initialization={initialization}
    handleTime={handleTime}
    clearTime={clearTime}
    handleTime2={handleTime2}
    clearTime2={clearTime2}
    timerLoad={timerLoad}
    clearLoad={clearLoad}
    setSeverity={setSeverity}
    setMessage={setMessage}
    setAlertOpen={setAlertOpen}
    block={block}
    modeStatus={modeStatus}
    device_data={device_data}
    />}
    </>
  );
};

export default Switch;
