import React from "react";
import { TextField, Box } from "@mui/material";

const TimeSwitch = ({disabled,setdisabled,disabledmessage, timer, setTimer, timeStatus, tooltip, setToolTip,initialization,setTimerDialogOpen }) => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <TextField
        id="time"
        label="On Time"
        type="text"
        size="small"
        // placeholder="HH:mm"
        value={timeStatus.st==="9999"?"":timeStatus.st}
        disabled={disabled||initialization}
        // disabled={true}
        InputLabelProps={{
          shrink: true,
          style: {
            color:
              timer.st === timeStatus.st && timer.et === timeStatus.et
                ? "#90aec6"
                : "#e0e0e0",
          },
        }}
        style={{ marginBottom: 16 }}
        inputProps={{style:{cursor:"pointer"}}}
        onClick={()=>setTimerDialogOpen(true)}
        // onChange={(e) => {
        //   setTimer({ ...timer, st: e.target.value });
        //   setToolTip({ ...tooltip, st: "1" });
        // }}
      />
      <TextField
        id="time"
        label="Off Time"
        type="text"
        size="small"
        value={timeStatus.et==="9999"?"":timeStatus.et}
        disabled={disabled||initialization}
        // disabled={true}
        InputLabelProps={{
          shrink: true,
          style: {
            color:
              timer.st === timeStatus.st && timer.et === timeStatus.et
                ? "#90aec6"
                : "#e0e0e0"
          },
        }}
        inputProps={{style:{cursor:"pointer"}}}
        onClick={()=>setTimerDialogOpen(true)}
        // onChange={(e) => {
        //   setTimer({ ...timer, et: e.target.value });
        //   setToolTip({ ...tooltip, et: "1" });
        // }}
      />
    </Box>
  );
};

export default TimeSwitch;
