/* eslint-disable array-callback-return */
import axiosIntance from "../helper/axios";

const getInitialMeteringData = (
  device_id,
  // setMeterData,
  full,
  setVoltageData,
  setCurrentData,
  setPowerData,
  setEnergy,
  setChartLoading,
  setSet,
  set,
  setPhase,
  setInitial,
  filterData,
  setTime,
  setEndTime,
  setStartIndex,
  loadMore
) => {
  if (full) {
    axiosIntance
      .get(`/energy/chart/data/initial/${device_id}?full=${full}&start_time=${filterData.start_time}&loadMore=${loadMore}`)
      .then((res) => {
        if(res.data.phase1.length===0&&res.data.phase2.length===0&&res.data.phase3.length===0) return;
        setEnergy(res.data.energy);
        if(loadMore===0||loadMore===-1)setTime(res.data.current_date);
        if(loadMore===0||loadMore===1)setEndTime(res.data.end_date);
        setStartIndex(res.data.startIndex);
        setPhase({
          phase1: res.data.phase1.has_phase1,
          phase2: res.data.phase2.has_phase2,
          phase3: res.data.phase3.has_phase3,
        });
        const reversedDate= res.data.date.reverse();
        const p1v=res.data.phase1.v.reverse();
        const p2v=res.data.phase2.v.reverse();
        const p3v=res.data.phase3.v.reverse();
        const p1i=res.data.phase1.i.reverse();
        const p2i=res.data.phase2.i.reverse();
        const p3i=res.data.phase3.i.reverse();
        const p1p=res.data.phase1.p.reverse();
        const p2p=res.data.phase2.p.reverse();
        const p3p=res.data.phase3.p.reverse();
        setVoltageData((prevData) => ({
          p1: loadMore===0?p1v:(loadMore===-1?[...p1v,...prevData.p1]:[...prevData.p1, ...p1v]),
          p2: loadMore===0?p2v:(loadMore===-1?[...p2v,...prevData.p2]:[...prevData.p2, ...p2v]),
          p3: loadMore===0?p3v:(loadMore===-1?[...p3v,...prevData.p3]:[...prevData.p3, ...p3v]),
          time: loadMore===0?reversedDate:(loadMore===-1?[...reversedDate,...prevData.time]:[...prevData.time, ...reversedDate]),
        }));
        setCurrentData((prevData) => ({
          p1: loadMore===0?p1i:(loadMore===-1?[...p1i,...prevData.p1]:[...prevData.p1, ...p1i]),
          p2: loadMore===0?p2i:(loadMore===-1?[...p2i,...prevData.p2]:[...prevData.p2, ...p2i]),
          p3: loadMore===0?p3i:(loadMore===-1?[...p3i,...prevData.p3]:[...prevData.p3, ...p3i]),
          time: loadMore===0?reversedDate:(loadMore===-1?[...reversedDate,...prevData.time]:[...prevData.time, ...reversedDate]),
        }));
        setPowerData((prevData) => ({
          p1: loadMore===0?p1p:(loadMore===-1?[...p1p,...prevData.p1]:[...prevData.p1, ...p1p]),
          p2: loadMore===0?p2p:(loadMore===-1?[...p2p,...prevData.p2]:[...prevData.p2, ...p2p]),
          p3: loadMore===0?p3p:(loadMore===-1?[...p3p,...prevData.p3]:[...prevData.p3, ...p3p]),
          time: loadMore===0?reversedDate:(loadMore===-1?[...reversedDate,...prevData.time]:[...prevData.time, ...reversedDate]),
        }));
        setChartLoading(false);
        setSet(!set);
        setInitial(false);
      })
      .catch((err) => {
        console.log(err);
      });
  } else {
    axiosIntance
      .get(`/energy/chart/data/initial/${device_id}?full=${full}`)
      .then((res) => {
        setVoltageData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
};

const syncDeviceEnergy = async (
  device_id,
  setMessage,
  setAlertOpen,
  setSeverity,
  setLoading
) => {
  setLoading(true);
  axiosIntance
    .post(`/energy/sync/${device_id}`)
    .then((res) => {
      setMessage(res.data.message);
      setAlertOpen(true);
      setSeverity("success");
      setLoading(false);
    })
    .catch((err) => {
      setAlertOpen(true);
      setMessage(err.response.data.error);
      setLoading(false);
      setSeverity("error");
    });
};

export { getInitialMeteringData, syncDeviceEnergy };
