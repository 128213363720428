import React, { useState, useEffect } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  InputBase,
  IconButton,
  Tooltip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
} from "@mui/material";
import { Search as SearchIcon, AddBox } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { createGroup, updateGroup } from "../../../api/group";
import { fetchAllDevices } from "../../../api/device";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#f0f0f0",
  "&:hover": {
    backgroundColor: "#f7f9fc",
  },
  marginLeft: 0,
  marginRight: 10,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const headers = [
  { id: "name", label: "Group Name" },
  { id: "code", label: "Group Code" },
  { id: "devices", label: "Devices", align: "center" },
  { id: "created_by", label: "Created By", align: "right" },
  { id: "updated_at", label: "Last Updated At", align: "right" },
  { id: "updated_by", label: "Updated By", align: "right" },
];

const GroupsTable = ({
  permission,
  groupData,
  reload,
  setReload,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  total,
  setAlertOpen,
  setSeverity,
  setMessage,
  userData,
  searchQuery,
  handleSearchChange
}) => {
  const [formOpen, setFormOpen] = useState(false);
  const [update, setUpdate] = useState(false);
  const [groupId, setGroupId] = useState(null);
  const [groupName, setGroupName] = useState("");
  const [formState, setFormState] = React.useState({
    deviceArray: [],
  });
  const [deviceData, setDeviceData] = useState([]);
  const [deviceLoading, setDeviceLoading] = useState(true);

  useEffect(() => {
    if (permission.includes("FETCH_DEVICES")) {
      fetchAllDevices(userData.user.company_id._id,setDeviceData, setDeviceLoading);
    } else {
      setDeviceLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFieldChange = (event) => {
    setFormState((formState) => ({
      ...formState,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    }));
  };

  const handleFormOpen = () => {
    setFormOpen(true);
  };

  const handleFormClose = () => {
    setReload(!reload);

    setGroupId();
    setGroupName("");
    setFormState({ deviceArray: [] });
    setFormOpen(false);
    setUpdate(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSubmit = async () => {
    // code to register user
    let data = {
      group_name: groupName,
      device_array: formState.deviceArray,
      company_id:userData.user.company_id._id
    };

    createGroup(data, setSeverity, setMessage, setAlertOpen, handleFormClose);
  };

  const handleUpdate = () => {
    // code to register user
    let grp = {
      group_name: groupName,
      device_array: formState.deviceArray,
      company_id:userData.user.company_id._id
    };

    updateGroup(
      groupId,
      grp,
      setSeverity,
      setMessage,
      setAlertOpen,
      handleFormClose
    );
  };

  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <div
          style={{
            margin: "16px 8px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" sx={{ ml: 1 }}>
            All Groups
          </Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
          <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search Groups"
                inputProps={{ "aria-label": "search" }}
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </Search>
            {permission.includes("CREATE_GROUP") && (
              <IconButton disabled={deviceLoading}>
                <Tooltip
                  title={deviceLoading ? "Loading Devices" : "Add Group"}
                  arrow
                  placement="top"
                >
                  <AddBox onClick={handleFormOpen} />
                </Tooltip>
              </IconButton>
            )}
          </div>
        </div>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {headers.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}

                {permission.includes("UPDATE_GROUP") && (
                  <TableCell align="center">Action</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {groupData.map((row) => (
                <TableRow key={row.group_code} role="checkbox" tabIndex={-1}>
                  <TableCell>{row.group_name}</TableCell>
                  <TableCell component="th" scope="row">
                    {row.group_code}
                  </TableCell>
                  <TableCell align="center">
                    {row.device_array.length}
                  </TableCell>
                  <TableCell align="right">{row.created_by}</TableCell>
                  <TableCell align="right">
                    {new Date(row.updatedAt).toLocaleString("en-IN", {
                      timeZone: "Asia/Kolkata",
                    })}
                  </TableCell>
                  <TableCell align="right">{row.updated_by}</TableCell>
                  {permission.includes("UPDATE_GROUP") && (
                    <TableCell align="center">
                      {" "}
                      <Button
                        variant="outlined"
                        color="secondary"
                        disabled={
                          row.group_name === "Admin Group" || deviceLoading
                        }
                        onClick={() => {
                          setGroupName(row.group_name);
                          setFormState((formState) => ({
                            ...formState,
                            deviceArray: row.device_array,
                          }));
                          setUpdate(true);
                          setGroupId(row._id);
                          setFormOpen(true);
                        }}
                      >
                        {deviceLoading ? "Loading Devices" : "Update"}
                      </Button>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      {/* Create/Update Group Modal Forms */}
      <Dialog open={formOpen} onClose={handleFormClose} maxWidth="xs" fullWidth>
        {update ? (
          <DialogTitle>Update Group</DialogTitle>
        ) : (
          <DialogTitle>Create New Group</DialogTitle>
        )}
        <DialogContent>
          <TextField
            margin="normal"
            required
            fullWidth
            id="GroupName"
            label="GroupName"
            value={groupName}
            name="GroupName"
            autoFocus
            onChange={(e) => {
              setGroupName(e.target.value);
            }}
          />
          {permission.includes("FETCH_DEVICES") && (
            <TextField
              // classes={{ root: classes.root }}
              margin="normal"
              fullWidth
              select
              name="deviceArray"
              id="deviceArray"
              variant="outlined"
              label="deviceArray"
              SelectProps={{
                multiple: true,
                value: formState.deviceArray,
                onChange: handleFieldChange,
              }}
            >
              {deviceData.map((data) => {
                return <MenuItem value={data._id} key={data._id}>{data.device_id}</MenuItem>;
              })}
            </TextField>
          )}
        </DialogContent>
        <DialogActions>
          {update ? (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 1, mb: 2, mr: 2, ml: 2, color: "#fff" }}
              onClick={handleUpdate}
            >
              Update Group
            </Button>
          ) : (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 1, mb: 2, mr: 2, ml: 2, color: "#fff" }}
              onClick={handleSubmit}
            >
              Create Group
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GroupsTable;
