import React, { useRef } from "react";
import { Box } from "@mui/material";
import "./styles/hero.css";

const Hero = ({ props, ref }) => {
  const clouds = useRef(null);
  const road = useRef(null);
  const roadLight = useRef(null);
  const title = useRef(null);
  const icon = useRef(null);

  window.addEventListener("scroll", function () {
    let value = window.scrollY;
    let zoom = 1;
    // let rX = 75;
    clouds.current.style.left = value * 1.05 + "px";
    roadLight.current.style.opacity = roadLight.current?value / 300 - 0.3:null;
    icon.current.style.transform = `scale(${(zoom -= value / 800)})`;
    title.current.style.fontSize = value / 5 + 32 + "px";
    // title.current.style.transform = `rotateX(${(rX -= value / 10)}deg)`;
  });

  return (
    <Box className="heroContainer">
      <Box ref={ref} className="heroSection">
        <img
          src="/assets/landing/clouds.png"
          alt="clouds"
          id="clouds"
          ref={clouds}
        />
        <img
          src="/assets/logo/icon-png.png"
          alt="Luminox"
          id="icon"
          ref={icon}
        />
        <img
          src="/assets/landing/road-lights.png"
          alt="lights"
          id="roadLights"
          ref={roadLight}
        />
        <img src="/assets/landing/road.png" alt="road" id="road" ref={road} />
        <div class="titleContainer" ref={title}>
          <p className="title">LUMINOX</p>
        </div>
      </Box>
    </Box>
  );
};

export default Hero;
