import React, { useContext, useEffect, useState } from "react";
import {
  Alert,
  Button,
  Box,
  Card,
  Container,
  Grid,
  Link,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { UserContext } from "./../../context/UserContext";
import { CircularProgress } from "@mui/material";
import { fetchUserData, loginUser } from "../../api/auth";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link
        color="secondary"
        href="https://www.thekaspertech.com/"
        sx={{ textDecoration: "none", fontWeight: 600 }}
      >
        KasperTech
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function Login() {
  const { userData, setUserData } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState();

  let navigate = useNavigate();

  useEffect(() => {
    if (userData.user) navigate("/dashboard");
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (e) => {
    // event.preventDefault();
    setLoading(true);
    try {
      e.preventDefault();
      const data = new FormData(e.currentTarget);
      const formData = {
        phone_no: data.get("phone_no"),
        password: data.get("password"),
      };
      const loginres = await loginUser(formData);
      localStorage.setItem("auth-token", loginres.data.token);
      const userData = await fetchUserData();
      // console.log(userData.data.data);
      setUserData({
        token: loginres.data.token,
        user: userData.data.data[0],
        planData: userData.data.data[0].plan,
        companies:userData.data.data
      });
      if(userData.data.data.length>1){
      navigate("/company",{state:{token:loginres.data.token,userData:userData.data.data}});
      }
      else{
      localStorage.setItem("company",userData.data.data[0].company_id._id);
      navigate("/dashboard");
      }
    } catch (err) {
      // console.log(err.response.data.error, "error");
      setOpen(true);
      setMessage(err.response.data.error);
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#213240",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: { xs: "none", sm: "center" },
      }}
    >
      <Box
        sx={{
          width: { xs: "80%", sm: 500 },
          mb: 4,
          mt: { xs: "15vh", sm: 0 },
        }}
      >
        <img
          src="/assets/logo/Logo-Lateral-Light.svg"
          alt="Luminox by KasperTech"
          width="100%"
          height="auto"
        />
      </Box>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          height: { xs: "75vh", sm: "fit-content" },
          borderRadius: { xs: "40px 40px 0 0", sm: "40px" },
        }}
        elevation={8}
      >
        <Container component="main" maxWidth="md">
          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleClose}
              severity="error"
              sx={{ width: "100%" }}
            >
              {message}
            </Alert>
          </Snackbar>

          <Typography
            sx={{ fontSize: "2.5rem", fontWeight: 600, mt: { xs: 6, sm: 4 } }}
            color="secondary"
          >
            Login
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: "5%" }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="phone_no"
                label="Phone Number"
                name="phone_no"
                autoComplete="phone_no"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <Button
                type="submit"
                fullWidth
                size="large"
                variant="contained"
                color="secondary"
                sx={{ mt: 3, mb: 2 }}
              >
                {loading ? (
                  <>
                    Loading{" "}
                    <CircularProgress
                      color="inherit"
                      size={20}
                      style={{ marginLeft: "8px" }}
                    />
                  </>
                ) : (
                  "Sign In"
                )}
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="/forget-password" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
              </Grid>
              <Copyright
                sx={{
                  display: { xs: "none", sm: "block" },
                  mt: 3,
                  mb: 4,
                }}
              />
            </Box>

            <Copyright
              sx={{
                position: "absolute",
                bottom: 0,
                mb: 1,
                display: { sm: "none" },
              }}
            />
          </Box>
        </Container>
      </Card>
    </Box>
  );
}
