// all the device apis

import axiosIntance from "../helper/axios";

const fetchAllDevices = (company_id,setDeviceData, setDeviceLoading) => {
  axiosIntance
    .get(`/device/all/${company_id}`)
    .then((res) => {
      setDeviceData(res.data.data);
      setDeviceLoading(false);
    })
    .catch((err) => {
      console.log(err);
      setDeviceLoading(false);
    });
};
const fetchDevice = (device_id,setEnergy) => {
  axiosIntance
    .get(`/device/data/${device_id}`)
    .then((res) => {
      setEnergy(res.data.data.energy);
    })
    .catch((err) => {
      console.log(err);
    });
};
const fetchAllGroupDevices = (group_id,setDeviceData, setDeviceLoading) => {
  axiosIntance
    .get(`/device/all/${group_id}`)
    .then((res) => {
      setDeviceData(res.data.data);
      setDeviceLoading(false);
    })
    .catch((err) => {
      console.log(err);
      setDeviceLoading(false);
    });
};
const bulkPublish = (
  groupId,
  data,
  setLoading,
  setAlertOpen,
  setMessage,
  setSeverity,
  handleClose
) => {
  axiosIntance
    .post(`/group/publish/bulk/${groupId}`, data)
    .then((res) => {
      setAlertOpen(true);
      setMessage(res.data.message);
      if(setLoading)setLoading(false);
      setSeverity("success");
      if(handleClose) handleClose();
    })
    .catch((err) => {
      setAlertOpen(true);
      setMessage(err.response.data.error);
      if(setLoading)setLoading(false);
      setSeverity("error");
      if(handleClose) handleClose();
    });
};
const getDeviceList = (
  user_id,
  setDeviceData,
  setLoading,
  table,
  page,
  limit,
  filterData,
  setTotal,
  setSeverity,
  setAlertOpen,
  setMessage,
  selectedGroups
) => {
    axiosIntance
      .get(`/device/list?selectedGroups=${selectedGroups}&user_id=${user_id}`)
      .then((res) => {
        console.log(res.data.data);
        setDeviceData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
};
export { fetchAllDevices,bulkPublish,fetchAllGroupDevices,getDeviceList,fetchDevice };
