// All the apis of authentication

const { default: axiosIntance } = require("../helper/axios");

const loginUser = async (formData) => {
  return await axiosIntance.post(`/auth/login`, formData);
};

const fetchUserData = async () => {
  return await axiosIntance.get(`/user/current/info`);
};
const fetchUserDataByCompany = async (comapny_id) => {
  return await axiosIntance.get(`/user/current/info/${comapny_id}`);
};

export { loginUser, fetchUserData,fetchUserDataByCompany };
