import React, { useEffect, useState,useContext } from "react";
import styled from "@emotion/styled";
import { Typography, Breadcrumbs, Divider, Link, Grid } from "@mui/material";

import MasterControl from "../dashboard/elements/MasterControl";
import { getGroupWiseDevices } from "../../api/group";
import Loader from "../../components/Loader";
import { UserContext } from "./../../context/UserContext";
const PageTitle = styled(Typography)`
  margin: 0px 0px 0.35em;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.25;
`;

const PathLink = styled(Link)`
  margin: 0px;
  color: rgb(55, 111, 208);
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
`;

const ActiveLink = styled(Typography)`
  margin: 0px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
`;

const Profile = () => {
  const [groupData, setGroupData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const { userData } = useContext(UserContext);

  useEffect(() => {
    getGroupWiseDevices(userData.user.company_id._id,setGroupData, setLoading, setError);
  }, [userData.user.company_id._id]);
  return (
    <div>
      <PageTitle>Master Controls</PageTitle>
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{ display: { xs: "none", lg: "block" } }}
      >
        <PathLink underline="hover" href="/dashboard">
          Dashboard
        </PathLink>
        <ActiveLink>Master Controls</ActiveLink>
      </Breadcrumbs>
      <Divider style={{ marginTop: 16, marginBottom: 16 }} />

      {loading && <Loader />}

      <Grid container spacing={3}>
        {!loading && groupData.length === 0 && <>{error}</>}

        {groupData.map((data) => {
          return (
            <Grid item xs={12} md={6} key={data._id}>
              <MasterControl data={data} />
            </Grid>
          );
        })}
      </Grid>

    </div>
  );
};

export default Profile;
