import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Tabs,
  Tab,
  Box,
  TextField,
  CircularProgress,
  Badge,
} from "@mui/material";

function TimerDialog({
  open,
  onClose,
  disabled,
  setdisabled,
  disabledmessage,
  timer,
  setTimer,
  timer2,
  setTimer2,
  timeStatus,
  timeStatus2,
  tooltip,
  setToolTip,
  tooltip2,
  setToolTip2,
  initialization,
  handleTime,
  clearTime,
  handleTime2,
  clearTime2,
  timerLoad,
  clearLoad,
  setSeverity,
  setMessage,
  setAlertOpen,
  block,
  modeStatus,
  device_data
}) {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleTimerSet = () => {
    if (selectedTab === 0) {
      if(timer.st===""||timer.et===""){
        setSeverity("error");
        setMessage("Enter a valid timer value");
        setAlertOpen(true);
        return;
      }
      if(timer2.st!=="9999"&&timer2.et!=="9999"){
      const [startHours, startMinutes] = timer2.st.split(':').map(Number);
      const startTime = startHours * 60 + startMinutes;
      const [startHours2, startMinutes2] = timer.st.split(':').map(Number);
      const startTime2 = startHours2 * 60 + startMinutes2;

        const [endHours, endMinutes] = timer2.et.split(':').map(Number);
        const endTime = endHours * 60 + endMinutes;
        const [endHours2, endMinutes2] = timer.et.split(':').map(Number);
        const endTime2 = endHours2 * 60 + endMinutes2;

        if ((startTime2 >= startTime && startTime2 <= endTime) || (endTime2 >= startTime && endTime2 <= endTime) || (startTime2 >= endTime && endTime2 >= startTime && startTime2 > endTime2) || (startTime2 <= startTime && (endTime2 >= startTime || endTime2 <= startTime2))) {
          setSeverity("error");
          setMessage("Timer-1 should not be in range of Timer-2");
          setAlertOpen(true);
          return;
        }
      }
      handleTime();
    } else {
      if(timer2.st===""||timer2.et===""){
        setSeverity("error");
        setMessage("Enter a valid timer value");
        setAlertOpen(true);
        return;
      }
      const [startHours, startMinutes] = timer.st.split(':').map(Number);
      const startTime = startHours * 60 + startMinutes;
      const [startHours2, startMinutes2] = timer2.st.split(':').map(Number);
      const startTime2 = startHours2 * 60 + startMinutes2;

      const [endHours, endMinutes] = timer.et.split(':').map(Number);
      const endTime = endHours * 60 + endMinutes;
      const [endHours2, endMinutes2] = timer2.et.split(':').map(Number);
      const endTime2 = endHours2 * 60 + endMinutes2;

      if ((startTime2 >= startTime && startTime2 <= endTime) || (endTime2 >= startTime && endTime2 <= endTime) || (startTime2 >= endTime && endTime2 >= startTime && startTime2 > endTime2) || (startTime2 <= startTime && (endTime2 >= startTime || endTime2 <= startTime2))
      ) {
        setSeverity("error");
        setMessage("Timer-2 should not be in range of Timer-1");
        setAlertOpen(true);
        return;
      }
      else {
        handleTime2();
      }
    }
  };
  const handleTimerClear = () => {
    if (selectedTab === 0) {
      clearTime();
    } else {
      clearTime2();
    }
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Timer Dialog</DialogTitle>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Timer" />
        {device_data?.feature_data?.some((feature)=>feature.feature_code==="DOUBLE_TIMER") && (<Tab label="Timer-2" disabled={(timer.st==="9999"&&timer.et==="9999")||!timer}/>)}
      </Tabs>
      <DialogContent>
        {selectedTab === 0 && (
          <Box
            component="form"
            sx={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            <TextField
              id="time"
              label="On Time"
              type="time"
              size="small"
              placeholder="HH:mm"
              value={timer.st}
              disabled={disabled || initialization}
              InputLabelProps={{
                shrink: true,
                style: {
                  color:
                    timer.st === timeStatus.st && timer.et === timeStatus.et
                      ? "#90aec6"
                      : "#e0e0e0",
                },
              }}
              style={{ marginBottom: 16 }}
              onChange={(e) => {
                setTimer({ ...timer, st: e.target.value });
                setToolTip({ ...tooltip, st: "1" });
              }}
            />
            <TextField
              id="time"
              label="Off Time"
              type="time"
              size="small"
              value={timer.et}
              disabled={disabled || initialization}
              InputLabelProps={{
                shrink: true,
                style: {
                  color:
                    timer.st === timeStatus.st && timer.et === timeStatus.et
                      ? "#90aec6"
                      : "#e0e0e0",
                },
              }}
              onChange={(e) => {
                setTimer({ ...timer, et: e.target.value });
                setToolTip({ ...tooltip, et: "1" });
              }}
            />
          </Box>
        )}
        {selectedTab === 1 && (
          <Box
            component="form"
            sx={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            <TextField
              id="time"
              label="On Time"
              type="time"
              size="small"
              placeholder="HH:mm"
              value={timer2.st}
              disabled={disabled || initialization}
              InputProps={{
                inputProps: {
                  min: '9:00',
                  max: '16:00'
                }
              }}
              InputLabelProps={{
                shrink: true,
                style: {
                  color:
                    timer2.st === timeStatus2.st && timer2.et === timeStatus2.et
                      ? "#90aec6"
                      : "#e0e0e0",
                },
              }}
              style={{ marginBottom: 16 }}
              onChange={(e) => {
                setTimer2({ ...timer2, st: e.target.value });
                setToolTip2({ ...tooltip2, st: "1" });
              }}
            />
            <TextField
              id="time"
              label="Off Time"
              type="time"
              size="small"
              value={timer2.et}
              disabled={disabled || initialization}
              InputLabelProps={{
                shrink: true,
                style: {
                  color:
                    timer2.st === timeStatus2.st && timer2.et === timeStatus2.et
                      ? "#90aec6"
                      : "#e0e0e0",
                },
              }}
              onChange={(e) => {
                setTimer2({ ...timer2, et: e.target.value });
                setToolTip2({ ...tooltip2, et: "1" });
              }}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Badge
          color="success"
          badgeContent="click"
          anchorOrigin={{
            horizontal: "left",
            vertical: "top",
          }}
          invisible={
            selectedTab === 0 ? !(
              (tooltip.st === "1" && timer.st !== "9999") ||
              (tooltip.et === "1" && timer.et !== "9999")
            ) : !((tooltip2.st === "1" && timer2.st !== "9999") ||
              (tooltip2.et === "1" && timer2.et !== "9999"))
          }
        >
          <Button
            variant="contained"
            style={{
              background: "#90aec6",
              color: "#fff",
              fontWeight: 600,
              marginRight: "16px"
            }}
            onClick={handleTimerSet}
            disabled={
              modeStatus === "0" ||
              block ||
              disabled ||
              initialization
            }
          >
            {timerLoad ? (
              <CircularProgress
                style={{ color: "white" }}
                size={21}
              />
            ) : (
              "SET"
            )}
          </Button>
        </Badge>
        <Button
          variant="contained"
          style={{
            background: "#90aec6",
            color: "#fff",
            fontWeight: 600,
          }}
          onClick={handleTimerClear}
          disabled={
            modeStatus === "0" ||
            block ||
            disabled ||
            initialization
          }
        >
          {clearLoad ? (
            <CircularProgress
              style={{ color: "white" }}
              size={21}
            />
          ) : (
            "CLEAR"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TimerDialog;
