import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { ExpandCircleDown } from "@mui/icons-material/";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  accordion: {
    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
    borderRadius: "4px",
    marginBottom: "16px",
    marginTop: "8px",
  },
  groupTitle: {
    fontFamily: "Ubuntu, sans-serif",
    fontWeight: 500,
  },
}));

export default function Group({ header, children }) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);
  return (
    <div style={{ marginBottom: "30px" }}>
      <Accordion
        className={classes.accordion}
        expanded={expanded}
        onChange={(e, isExpanded) => setExpanded(isExpanded)}
      >
        <AccordionSummary
          expandIcon={
            <ExpandCircleDown
              sx={
                expanded
                  ? { color: (theme) => theme.palette.primary.main }
                  : { color: (theme) => theme.palette.secondary.main }
              }
            />
          }
          className={classes.summary}
        >
          <Typography
            variant="h5"
            component="div"
            gutterBottom
            className={classes.groupTitle}
            sx={
              expanded ? { color: (theme) => theme.palette.primary.main } : null
            }
          >
            {header}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </div>
  );
}
