import { Instagram, LinkedIn, Twitter } from "@mui/icons-material";
import { Avatar, Box, Container, Grid, Typography, Link } from "@mui/material";
import React from "react";
import "./styles/footerStyles.css";

const Footer = ({
  scrollToSection,
  heroSection,
  aboutSection,
  featureSection,
  contactSection,
}) => {
  return (
    <React.Fragment>
      <Box sx={{ backgroundColor: "#1d1f21", color: "#fff" }}>
        <Container>
          <Grid container spacing={5} sx={{ pt: 8, pb: 4 }}>
            <Grid item xs={12} md={6} lg={3}>
              <Typography>
                <span className="brand">KasperTech</span>
              </Typography>
              <Typography variant="subtitle2">
                KasperTech is an Urban Automation Startup founded by a team of
                passionate Engineers. We manifest in creating smart services for
                homes, offices and automobiles. Our vision is to bring
                automation technology to people at modest estimates while
                thriving to build what we call a futuristic present.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <p variant="h6" sx={{ mt: 1 }} className="footerTitle">
                USEFUL LINKS
              </p>
              <ul className="useful-link">
                <li className="useful-link-item">
                  <a
                    href="https://thekaspertech.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Company
                  </a>
                </li>
                <li
                  className="useful-link-item"
                  onClick={() => scrollToSection(heroSection)}
                >
                  Home
                </li>
                <li
                  className="useful-link-item"
                  onClick={() => scrollToSection(aboutSection)}
                >
                  About
                </li>
                <li
                  className="useful-link-item"
                  onClick={() => scrollToSection(featureSection)}
                >
                  Features
                </li>
                <li
                  className="useful-link-item"
                  onClick={() => scrollToSection(contactSection)}
                >
                  Contact
                </li>
              </ul>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <p variant="h6" sx={{ mt: 1 }} className="footerTitle">
                CONTACT
              </p>
              <Typography variant="subtitle2" sx={{ mb: 1 }}>
                GUSEC, Gujarat University <br /> Ahmedabad (39009), Gujarat,
                INDIA
                <br />
                <b>Contact:</b> +91 7877637947
                <br />
                <b>Email:</b> contact@thekaspertech.com
              </Typography>
              <Box sx={{ display: "flex" }}>
                <Avatar
                  component={Link}
                  href="https://www.linkedin.com/company/kaspertech/"
                  target="_blank"
                  sx={{
                    width: 32,
                    height: 32,
                    mr: 1,
                    bgcolor: "#808080",
                    cursor: "pointer",
                  }}
                >
                  <LinkedIn />
                </Avatar>
                <Avatar
                  component={Link}
                  href="https://twitter.com/kasper_tech"
                  target="_blank"
                  sx={{
                    width: 32,
                    height: 32,
                    mr: 1,
                    bgcolor: "#808080",
                    cursor: "pointer",
                  }}
                >
                  <Twitter />
                </Avatar>
                <Avatar
                  component={Link}
                  href="https://www.instagram.com/kasper_tech/"
                  target="_blank"
                  sx={{
                    width: 32,
                    height: 32,
                    mr: 1,
                    bgcolor: "#808080",
                    cursor: "pointer",
                  }}
                >
                  <Instagram />
                </Avatar>
                <Avatar
                  component={Link}
                  href="https://medium.com/kaspertech/"
                  target="_blank"
                  sx={{
                    width: 32,
                    height: 32,
                    mr: 1,
                    bgcolor: "#808080",
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                >
                  <span className="medium-icon">M</span>
                </Avatar>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <p variant="h6" sx={{ mt: 1 }} className="footerTitle">
                STAY TUNED
              </p>
              <Typography variant="subtitle2">
                Found our work interesting? Drop your mail here to stay tuned to
                our latest updates. We won't spam your mailbox with unnecessary
                notifications.
              </Typography>
              <Box sx={{ display: "flex" }}>
                <input placeholder="Email" className="subscribe-input" />
                <button className="subscribe-button">Subscribe</button>
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Grid container>
          <Grid
            item
            xs={12}
            textAlign="center"
            sx={{ backgroundColor: "#1c1f18", p: 2 }}
          >
            &copy; Copyright{" "}
            <a
              href="https://thekaspertech.com"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none", fontWeight: 600, color: "#fff" }}
            >
              KasperTech
            </a>
            . All Rights Reserved
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default Footer;
