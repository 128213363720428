import React, { useEffect, useContext, useState } from "react";
import styled from "@emotion/styled";
import {
  Typography,
  Breadcrumbs,
  Divider,
  Link,
  Snackbar,
  Alert,
} from "@mui/material";

import Loader from "../../components/Loader";
import { UserContext } from "../../context/UserContext";
import UsersTable from "./elements/UsersTable";
import { fetchAllUserCompany } from "../../api/users";

const PageTitle = styled(Typography)`
  margin: 0px 0px 0.35em;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.25;
`;

const PathLink = styled(Link)`
  margin: 0px;
  color: rgb(55, 111, 208);
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
`;

const ActiveLink = styled(Typography)`
  margin: 0px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
`;

const User = () => {
  const { userData } = useContext(UserContext);
  const permission = userData.user.role_id.permissions;

  const [loading, setLoading] = useState(true);
  const [usData, setUsData] = useState([]);
  const [reload, setReload] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [alertOpen, setAlertOpen] = useState(false);
  const [severity, setSeverity] = useState();
  const [message, setMessage] = useState();
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    setLoading(true);
    fetchAllUserCompany(
      userData.user.company_id._id,
      page,
      rowsPerPage,
      setUsData,
      setLoading,
      setTotal,
      setAlertOpen,
      setSeverity,
      setMessage,
      searchQuery
    );
  }, [reload, rowsPerPage, page]);

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  let debounceTimer;
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);

    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      setPage(0);
      fetchAllUserCompany(
        userData.user.company_id._id,
        page,
        rowsPerPage,
        setUsData,
        setLoading,
        setTotal,
        setAlertOpen,
        setSeverity,
        setMessage,
        event.target.value
      );
    }, 1000);
  };

  return (
    <>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleAlertClose}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
      <PageTitle>USERS</PageTitle>
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{ display: { xs: "none", lg: "block" } }}
      >
        <PathLink underline="hover" href="/">
          Dashboard
        </PathLink>
        <ActiveLink>Users</ActiveLink>
      </Breadcrumbs>
      <Divider style={{ margin: "16px 0" }} />
      {loading ? (
        <Loader data="Users" />
      ) : (
        <UsersTable
          permission={permission}
          usData={usData}
          user={userData.user}
          reload={reload}
          setReload={setReload}
          total={total}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setAlertOpen={setAlertOpen}
          setMessage={setMessage}
          setSeverity={setSeverity}
          searchQuery={searchQuery}
          handleSearchChange={handleSearchChange}
        />
      )}
    </>
  );
};

export default User;
