import React from "react";
import { Box } from "@mui/material";

const Footer = () => {
  return (
    <Box sx={{ display: { xs: "none", sm: "block" } }}>
      <div
        md={{
          width: "calc(100vw - 240px)",
        }}
        style={{
          padding: 32,
          height: 64,
          display: "flex",
          alignItems: "center",
          backgroundColor: "#fff",
          justifyContent: "flex-end",
          marginLeft: -24,
          marginBottom: -24,
          marginRight: -24,
          right: 0,
        }}
      >
        {" "}
        © 2020-{new Date().getFullYear()} KasperTech{" "}
      </div>
    </Box>
  );
};

export default Footer;
