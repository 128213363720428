import React, { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Typography, Breadcrumbs, Divider, Link } from "@mui/material";
import axiosIntance from "../../helper/axios";
import Loader from "../../components/Loader";
import { UserContext } from "../../context/UserContext";

import RolesTable from "./elements/RolesTable";

const PageTitle = styled(Typography)`
  margin: 0px 0px 0.35em;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.25;
`;

const PathLink = styled(Link)`
  margin: 0px;
  color: rgb(55, 111, 208);
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
`;

const ActiveLink = styled(Typography)`
  margin: 0px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
`;
const Role = () => {
  const { userData } = useContext(UserContext);
  const permission = userData.user.role_id.permissions;

  const [loading, setLoading] = React.useState(true);
  const [roleData, setRoleData] = React.useState([]);

  const [filterOpen, setFilterOpen] = useState(false);
  const [filterData, setFilterData] = useState({
    start_time: "",
    end_time: "",
  });
  const [refresh,setRefresh]=useState(false);
  const handleFormClose = () => {
    setFilterOpen(false);
  };
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    axiosIntance
      .get(`/role/fetch/${userData.user.company_id._id}?page=${page + 1}&limit=${rowsPerPage}&searchTerm=${searchQuery}`)
      .then((res) => {
        setRoleData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "error");
        setLoading(false);
      });
  }, [page, rowsPerPage,refresh]);

  let debounceTimer;
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);

    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      setPage(0);
      axiosIntance
      .get(`/role/fetch/${userData.user.company_id._id}?page=${page + 1}&limit=${rowsPerPage}&searchTerm=${event.target.value}`)
      .then((res) => {
        setRoleData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "error");
        setLoading(false);
      });
    }, 1000);
  };
  return (
    <>
      <PageTitle>ROLES</PageTitle>
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{ display: { xs: "none", lg: "block" } }}
      >
        <PathLink underline="hover" href="/">
          Dashboard
        </PathLink>
        <ActiveLink>Roles</ActiveLink>
      </Breadcrumbs>
      <Divider style={{ margin: "16px 0" }} />
      {loading ? (
        <Loader data="Users" />
      ) : (
        <RolesTable roleData={roleData}
        refresh={refresh}
        setRefresh={setRefresh}
        setFilterOpen={setFilterOpen}
        filterData={filterData}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        permission={permission} 
        searchQuery={searchQuery}
        handleSearchChange={handleSearchChange}
        />
      )}
    </>
  );
};

export default Role;
