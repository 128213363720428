import React, { useState } from "react";
import styled from "@emotion/styled";
import { Typography, Breadcrumbs, Divider, Link } from "@mui/material";
import PlansTable from "./elements/PlanTable";
import PlanFilter from "./elements/PlanFilter";

const PageTitle = styled(Typography)`
  margin: 0px 0px 0.35em;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.25;
`;

const PathLink = styled(Link)`
  margin: 0px;
  color: rgb(55, 111, 208);
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
`;

const ActiveLink = styled(Typography)`
  margin: 0px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
`;

const Plans = () => {
  const [filterOpen, setFilterOpen] = useState(false);
  const [filterData, setFilterData] = useState({
    start_time: "",
    end_time: "",
  });
  const [refresh,setRefresh]=useState(false);
  const handleFormClose = () => {
    setFilterOpen(false);
  };
  return (
    <>
      <PageTitle>Plans</PageTitle>
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{ display: { xs: "none", lg: "block" } }}
      >
        <PathLink underline="hover" href="/">
          Dashboard
        </PathLink>
        <ActiveLink>Plans</ActiveLink>
      </Breadcrumbs>
      <Divider style={{ margin: "16px 0" }} />
      <PlansTable 
      refresh={refresh}
      setRefresh={setRefresh}
      setFilterOpen={setFilterOpen}
      filterData={filterData}
      />
      <PlanFilter
            filterOpen={filterOpen}
            handleFilterClose={handleFormClose}
            refresh={refresh}
            setRefresh={setRefresh}
            filterData={filterData}
            setFilterData={setFilterData}
          />
    </>
  );
};

export default Plans;
