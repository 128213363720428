import React, { useContext, useEffect, useState } from "react";
import {
  Alert,
  Button,
  Box,
  Card,
  Snackbar,
  TextField,
  Typography,MenuItem
} from "@mui/material";
import { useNavigate,useLocation } from "react-router-dom";
import { UserContext } from "./../../context/UserContext";
import { CircularProgress } from "@mui/material";
import { fetchUserData } from "../../api/auth";


const Company = (props) => {
  const { setUserData } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
    const [company,setcompany]=useState("");
    const [open, setOpen] = useState(false);
  const [message, setMessage] = useState();
  const location=useLocation();
  const [user,setUser]= useState([]);
  useEffect(()=>{
    async function fetchUserCompanies(){
      const data = await fetchUserData();
      setUser(data.data.data);
    }
    fetchUserCompanies();
  },[])
  
  let navigate = useNavigate();
    const handlecompanychange=(e)=>{
        setcompany(e.target.value);
    }
    const handleSubmit=async(e)=>{
        setLoading(true);
    try {
      e.preventDefault();
      localStorage.setItem("company",company.company_id._id);
      const com= company.company_id._id;
        const u= user.filter((puser)=>
          puser.company_id._id===com)
        setUserData({
          token:location.state.token,
          user: u[0],
          planData: u[0].plan,
          companies:user
        });
      navigate("/dashboard");
    } catch (err) {
      // console.log(err.response.data.error, "error");
      setOpen(true);
      setMessage(err.response.data.error);
      setLoading(false);
    }
    }
    const handleClose = () => {
      setOpen(false);
    };
  return (
    <>
      <Box
      sx={{
        backgroundColor: "#213240",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: { xs: "none", sm: "center" },
      }}
    >
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          height: {sm: "fit-content" },
          borderRadius: { xs: "20px 20px 20px 20px", sm: "20px" },
          margin: { xs: "auto" },
        }}
        elevation={8}
      >
        <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleClose}
              severity="error"
              sx={{ width: "100%" }}
            >
              {message}
            </Alert>
          </Snackbar>
          <Typography
            sx={{ fontSize: "2.5rem", fontWeight: 600, margin: "10px 50px" }}
            color="secondary"
          >
            Select Company
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: "5%" ,margin:"0px 50px",width:"100%"}}
            >
        <TextField
          select
          margin="normal"
          fullWidth
          label="Select Company"
          SelectProps={{
          value:company,
          onChange: handlecompanychange
          }}
        >
          {user&&user.map((u)=>{
            return <MenuItem value={u} key={u.company_id._id}>{u.company_id.company_name}</MenuItem>
          })}
          </TextField>
              <Button
                type="submit"
                fullWidth
                size="large"
                variant="contained"
                color="secondary"
                sx={{ mt: 3, mb: 2 }}
              >
                {loading ? (
                  <>
                    Loading{" "}
                    <CircularProgress
                      color="inherit"
                      size={20}
                      style={{ marginLeft: "8px" }}
                    />
                  </>
                ) : (
                  "Go to Dashboard"
                )}
              </Button>
            </Box>
          </Box>
      </Card>
    </Box>
    </>
    
  );
};

export default Company;
