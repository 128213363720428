import React, { useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { Lightbulb, TipsAndUpdates } from "@mui/icons-material";
import { bulkPublish } from "../../../../../api/device";

const ToggleSwitch = ({ groupId, setAlertOpen, setMessage, setSeverity }) => {
  const [fixLoading, setFixLoading] = useState(false);
  const [clearLoading, setClearLoading] = useState(false);

  const handleSwitch = (data, setLoading) => {
    setLoading(true)
    bulkPublish(
      groupId,
      data,
      setLoading,
      setAlertOpen,
      setMessage,
      setSeverity
    );
  };

  return (
    <React.Fragment>
      {/* <Grid container sx={{ marginTop: "10px" }} spacing={2}> */}
        <Grid item xs={12} md={1.5}>
          <Typography variant="body1">Switch</Typography>
        </Grid>
        <Grid item xs={6} md={2.5}>
          <Button
            startIcon={<TipsAndUpdates />}
            variant="outlined"
            color="warning"
            fullWidth
            disabled={clearLoading}
            onClick={(e) => {
              let dt = {
                channel: "switch",
                data: "1",
              };
              handleSwitch(dt, setFixLoading);
            }}
          >
            {fixLoading ? "Loading.." : "On"}
          </Button>
        </Grid>
        <Grid item xs={6} md={2.5}>
          <Button
            startIcon={<Lightbulb />}
            fullWidth
            variant="outlined"
            disabled={fixLoading}
            onClick={(e) => {
              let dt = {
                channel: "switch",
                data: "0",
              };
              handleSwitch(dt, setClearLoading);
            }}
          >
            {clearLoading ? "Loading.." : "Off"}
          </Button>
        </Grid>
      {/* </Grid> */}
    </React.Fragment>
  );
};

export default ToggleSwitch;
