import React, { useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  InputBase,
  Button,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Tooltip
} from '@mui/material';
import { FilterList, Search as SearchIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: '#f0f0f0',
  '&:hover': {
    backgroundColor: '#f7f9fc',
  },
  marginLeft: 0,
  marginRight: 10,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

const headers = [
  { id: 'name', label: 'Role Name' },
  { id: 'created_at', label: 'Created At' },
  { id: 'created_by', label: 'Created By' },
  { id: 'permissions', label: 'Permissions', align: 'center' },
];

const RolesTable = ({ 
  roleData,
  refresh,
  setRefresh,
  setFilterOpen,
  filterData ,
  page, 
  setPage,
  rowsPerPage,
  setRowsPerPage,
  searchQuery,
  handleSearchChange
}) => {
  const [permissionsOpen, setPermissionsOpen] = useState(false);
  const [permissionsData, setPermissionsData] = useState({
    name: '',
    permissions: [],
  });

  const handlePermissionsOpen = (name, permissions) => {
    setPermissionsData({
      name: name,
      permissions: permissions,
    });
    setPermissionsOpen(true);
  };

  const handlePermissionsClose = () => {
    setPermissionsOpen(false);
    setPermissionsData({ name: '', permissions: [] });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <div
          style={{
            margin: '16px 8px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant='h6' sx={{ ml: 1 }}>
            Roles
          </Typography>
          <div style={{ display: 'flex', alignItems: 'center' }}>
          <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search Roles"
                inputProps={{ "aria-label": "search" }}
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </Search>
          </div>
        </div>
        <TableContainer>
          <Table stickyHeader aria-label='sticky table'>
            <TableHead>
              <TableRow>
                {headers.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {roleData.map((row) => {
                return (
                  <TableRow hover role='checkbox' tabIndex={-1} key={row._id}>
                    <TableCell component='th' scope='row'>
                      {row.role_name}
                    </TableCell>
                    <TableCell>
                      {new Date(row.createdAt).toLocaleString('en-IN', {
                        timeZone: 'Asia/Kolkata',
                      })}
                    </TableCell>
                    <TableCell>{row.created_by}</TableCell>
                    <TableCell align='center'>
                      <Button
                        onClick={() =>
                          handlePermissionsOpen(row.role_name, row.permissions)
                        }
                      >
                        VIEW ({row.permissions.length})
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component='div'
          count={10}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      {/* Permission List Dilog */}
      <Dialog open={permissionsOpen} onClose={handlePermissionsClose}>
        <DialogTitle>Permissions of {permissionsData.name}</DialogTitle>
        <List style={{ paddingLeft: 8 }}>
          {permissionsData.permissions.map((permission) => (
            <ListItem>
              <ListItemText primary={permission} />
            </ListItem>
          ))}
        </List>
      </Dialog>
    </>
  );
};

export default RolesTable;
