// Apis for plans

import axiosIntance from "../helper/axios";

const getAllCompanyPlans = (
  company_id,
  setRows,
  setLoading,
  setAlertOpen,
  page,
  limit,
  setTotal,
  filter
) => {
  setLoading(true);
  axiosIntance
    .get(`/plans/company/all/${company_id}?page=${page + 1}&limit=${limit}&start_time=${filter.start_time}&end_time=${filter.end_time}`)
    .then((res) => {
      setRows(res.data.data.plan_data);
      setTotal(res.data.data.metadata.total_results);
      setLoading(false);
    })
    .catch((err) => {
      console.log(err.response.data.error);
      setLoading(false);
      setAlertOpen(true);
    });
};

export { getAllCompanyPlans };
