import React, { useContext } from "react";
import {
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import { UserContext } from "../../../../../context/UserContext";
// import './toggle.css';

const Toggle = ({
  state,
  mode,
  modeStatus,
  Id,
  client,
  block,
  disabled,
  setdisabled,
  setdisabledmessage,
  switchLoading,
  setSwitchLoading,
  showmodal,
  active,
  initialization
}) => {
  const { userData } = useContext(UserContext);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={6} style={{ width: "100%" }}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  disabled={
                    mode === "0" ||
                    modeStatus === "0" ||
                    block ||
                    disabled ||
                    switchLoading||
                    initialization
                  }
                  checked={state === "1"}
                  onChange={(e) => {
                    setSwitchLoading(true);
                    setdisabled(true);
                    setdisabledmessage(
                      "An Operation is going on. Please wait to perform another action."
                    );
                    if (active === "offline") {
                      showmodal(
                        "Your Operation will be Performed Once device comes Online."
                      );
                    }

                    let data = state === "1" ? "0" : "1";
                    let publish_data = {
                      data: data,
                      token: userData.user.token,
                      // metaData:"Toggle by App"
                    };
                    var options = {
                      retain: true,
                      qos: 1,
                    };

                    client.publish(
                      `${Id}/switch`,
                      JSON.stringify(publish_data),
                      options
                    );
                  }}
                />
              }
              label={
                <Grid item xs={6} style={{ width: "100%" }}>
                  {switchLoading ? (
                    <CircularProgress size={15} />
                  ) : (
                    <Typography variant="subtitle1" color="textSecondary">
                      {state === "1" ? "ON" : "OFF"}
                    </Typography>
                  )}
                </Grid>
              }
            />
          </FormGroup>
        </Grid>
      </Grid>
    </div>
  );
};

export default Toggle;
