import React, { useContext } from "react";
import {
  Drawer,
  Box,
  ListItem,
  ListItemIcon,
  ListItemText,
  List,
} from "@mui/material";

import SidebarList from "./elements/SidebarList";
import theme from "../../theme";
import { ExitToApp } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";

let secondary = theme.palette.secondary.main;

const Sidebar = ({ mobileOpen, drawerWidth, handleDrawerToggle }) => {
  const navigate = useNavigate();
  const { setUserData } = useContext(UserContext);

  const handleLogout = () => {
    localStorage.clear();
    setUserData("");

    navigate("/login");
  };

  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        style={{ backgroundColor: secondary }}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            background: secondary,
            color: "#fff",
            height: "100vh",
          },
        }}
      >
        <SidebarList
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
        />
        <List
          button="true"
          onClick={handleLogout}
          sx={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            background: "#1E2A38",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ListItem key={0} sx={{ maxWidth: "fit-content" }}>
            <ListItemIcon sx={{ mr: -3, ml: -1 }}>
              <ExitToApp sx={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText primary={"Logout"} sx={{ maxWidth: "fit-content" }} />
          </ListItem>
        </List>
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            background: secondary,
            color: "#fff",
          },
        }}
        open
      >
        <SidebarList
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
        />
      </Drawer>
    </Box>
  );
};

export default Sidebar;
