import {
    Box,
    Card,
    CardContent,
    CircularProgress,
    Typography,
    Breadcrumbs,
  Divider,
  Link,
  } from "@mui/material";
  import React, { useEffect, useState, useContext } from "react";
  import { UserContext } from "../../context/UserContext";
  import { fetchEnergyNotifications } from "../../api/notification";
import styled from "@emotion/styled";
  
  const PageTitle = styled(Typography)`
  margin: 0px 0px 0.35em;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.25;
`;

const PathLink = styled(Link)`
  margin: 0px;
  color: rgb(55, 111, 208);
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
`;

const ActiveLink = styled(Typography)`
  margin: 0px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
`;
  
  const EnergyNotification = () => {
    const { userData } = useContext(UserContext);
    
    const [notifications, setNotifications] = useState([]);
    const [dataByDate, setDataByDate] = useState([]);
    const [isLoading, setLoading] = useState(true);
  
  
    const dateConvertor = (date, pos) => {
      let dt = new Date(date).toLocaleString("en-US", {
        timeZone: "Asia/Kolkata",
        hour12: true,
        dateStyle: pos === "head" ? "short" : "medium",
        timeStyle: "medium",
      });
      return pos === "head"
        ? ` ${dt.split("/")[1]}/${dt.split("/")[0]}/${dt
            .split("/")[2]
            .slice(0, 2)}`
        : `${dt.split(",")[0]} ${dt.split(",")[1]}`;
    };
  
    const getTime = (date) => {
      let dt = new Date(date).toLocaleString("en-US", {
        timeZone: "Asia/Kolkata",
        timeStyle: "medium",
      });
      return `${dt.split(":")[0]}:${dt.split(":")[1]} ${dt.slice(-2)}`;
    };
    useEffect(() => {
      fetchEnergyNotifications(userData.user._id,userData.user.company_id._id, setNotifications, setLoading);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
      if (notifications) {
        // console.log(notifications);
        const groups = notifications?.reduce((grp, not) => {
          let date = new Date(not.createdAt).toLocaleString("en-US", {
            timeZone: "Asia/Kolkata",
            hour12: true,
            dateStyle: "short",
          });
          // console.log(date);
          if (!grp[date]) {
            grp[date] = [];
          }
          grp[date].push(not);
          return grp;
        }, {});
        let dateData = [];
        Object.keys(groups).forEach(function (key, i) {
          dateData.push(groups[key]);
        });
        // console.log(dataByDate);
        setDataByDate(dateData);
      }
    }, [notifications]);
  
    return (
      <>
      {" "}
      <PageTitle>Energy Notifications</PageTitle>
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{ display: { xs: "none", lg: "block" }, mt: 1 }}
      >
        <PathLink underline="hover" href="/">
          Dashboard
        </PathLink>
        <ActiveLink>Energy Notification</ActiveLink>
      </Breadcrumbs>
      <Divider style={{ marginTop: 16, marginBottom: 16 }} />
      <Box sx={{ pb: 0, pt: 0, height: { sm: "87vh", xs: "91vh" } }}>
        <Box
          sx={{
            height: "100%",
            overflowY: "scroll",
            pb: 0,
            "&::-webkit-scrollbar": {
              width: 0,
            },
            display: "flex",
            flexDirection: "column",
            justifyContent: (isLoading || dataByDate.length === 0) && "center",
            alignItem: "center",
          }}
        >
          {isLoading ? (
            <Box sx={{ textAlign: "center" }}>
              <CircularProgress size={35} />
            </Box>
          ) : // {console.log(dataByDate)}
          dataByDate && dataByDate.length > 0 ? (
            dataByDate.map((detail, i) => {
              return (
                <Box>
                  {/* {console.log(detail[0].notification_time)} */}
                  <Typography variant="body1" sx={{ fontWeight: 600, mt: 2 }}>
                    {dateConvertor(detail[0].createdAt, "bottom")}
                  </Typography>
  
                  {detail.map((notification) => (
                    <Card sx={{ mb: 2, mt: 2 }}>
                      {/* ★ */}
                      {notification.is_read === false ? (
                        <div
                          style={{
                            float: "right",
                            color: "red",
                            position: "relative",
                            bottom: "2px",
                            right: "6px",
                          }}
                        >
                          *
                        </div>
                      ) : (
                        ""
                      )}
                      <CardContent>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            height: "100%",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          <Box>
                            <Typography
                              sx={{
                                fontSize: { xs: 14, sm: 16, md: 18 },
                                fontWeight: 600,
                              }}
                            >
                              {notification?.notification_data.title}
                            </Typography>
                            <Typography
                              sx={{ fontSize: { xs: 12, sm: 14, md: 16 } }}
                            >
                              <pre>{notification?.notification_data.body}</pre>
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              sx={{ fontSize: { xs: 12, sm: 14, md: 16 } }}
                            >
                              {getTime(notification?.createdAt)}
                            </Typography>
                            <Typography
                              sx={{ fontSize: { xs: 12, sm: 14, md: 16 } }}
                            >
                              {dateConvertor(notification?.createdAt, "head")}
                            </Typography>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  ))}
                </Box>
              );
            })
          ) : (
            <Typography sx={{ textAlign: "center" }}>
              No Notification Found
            </Typography>
          )}
        </Box>
      </Box>
      </>
    );
  };
  
  export default EnergyNotification;
  