/* eslint-disable no-unused-vars */
import React, { useContext, useState } from "react";
import {
  Grid,
  FormControlLabel,
  Switch,
  CircularProgress,
  Box,
  Card,
  CardContent,
  Divider,
  Typography,
  Snackbar,
  Alert,
  Link,
  Breadcrumbs,
} from "@mui/material";
import { changeNotificationSetting } from "../../../api/notification";
import { UserContext } from "../../../context/UserContext";
import styled from "@emotion/styled";

const PageTitle = styled(Typography)`
  margin: 0px 0px 0.35em;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.25;
`;

const PathLink = styled(Link)`
  margin: 0px;
  color: rgb(55, 111, 208);
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
`;

const ActiveLink = styled(Typography)`
  margin: 0px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
`;

const NotificationSetting = () => {
  const { userData, setUserData } = useContext(UserContext);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [severity, setSeverity] = useState();
  const [message, setMessage] = useState("");
  const [switchLoading, setSwitchLoading] = useState([]);
  const [disabled, setdisabled] = useState([]);
  const [disabledmessage, setdisabledmessage] = useState("");

  const handleClose = () => {
    setAlertOpen(false);
  };

  const [notification, setNotification] = useState({
    timer: userData?.user?.notification?.timer || false,
    switch: userData?.user?.notification?.switch || false,
    timer_set: userData?.user?.notification?.timer_set || false,
    physical: userData?.user?.notification?.physical || false,
  });

  const handleChange = (data, setting_name, company_id) => {
    setdisabled((prev) => [...prev, setting_name]);
    setSwitchLoading((prev) => [...prev, setting_name]);
    changeNotificationSetting(
      data,
      setdisabled,
      setdisabledmessage,
      setSwitchLoading,
      setAlertOpen,
      setSeverity,
      setMessage,
      setting_name,
      company_id
    );
  };
  return (
    <React.Fragment>
      <PageTitle>Settings</PageTitle>
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{ display: { xs: "none", lg: "block" } }}
      >
        <PathLink underline="hover" href="/dashboard">
          Dashboard
        </PathLink>
        <PathLink underline="hover" href="/dashboard/setting">
          Settings
        </PathLink>
        <ActiveLink>Notification</ActiveLink>
      </Breadcrumbs>
      <Divider style={{ marginTop: 16, marginBottom: 16 }} />
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <Box sx={{ minWidth: 275 }}>
        <Card>
          <CardContent>
            <Typography variant="h6">Notification</Typography>

            <Typography
              inline="true"
              variant="caption"
              color="GrayText"
              component="div"
              sx={{ marginBottom: "16px" }}
            >
              Manage your notification configurations here
            </Typography>

            <Divider style={{ marginBottom: "16px" }} />
            <Grid container spacing={0}>
              {userData?.user.company_id.notification.switch === true && (
                <Grid item md={3} xs={12}>
                  <FormControlLabel
                    label="Load On/Off through Switch"
                    labelPlacement="start"
                    control={
                      <Switch
                        inputProps={{
                          "aria-label": "Load On/Off through Switch",
                        }}
                        checked={notification.switch}
                        disabled={
                          disabled.includes("switch") ||
                          switchLoading.includes("switch")
                        }
                        onChange={(e) => {
                          setNotification({
                            ...notification,
                            switch: e.target.checked,
                          });
                          handleChange(
                            {
                              ...notification,
                              switch: e.target.checked,
                            },
                            "switch",
                            userData?.user.company_id._id
                          );
                        }}
                      />
                    }
                  />
                  {switchLoading.includes("switch") ? (
                    <CircularProgress
                      size={15}
                      style={{ marginLeft: "10px" }}
                    />
                  ) : (
                    ""
                  )}
                </Grid>
              )}
              {userData.user.company_id.notification.timer && (
                <Grid item md={3} xs={12}>
                  <FormControlLabel
                    label="Load On/Off through timer"
                    labelPlacement="start"
                    disabled={
                      disabled.includes("timer") ||
                      switchLoading.includes("timer")
                    }
                    control={
                      <Switch
                        inputProps={{
                          "aria-label": "Load On/Off through timer",
                        }}
                        checked={notification.timer}
                        onChange={(e) => {
                          setNotification({
                            ...notification,
                            timer: e.target.checked,
                          });
                          handleChange(
                            {
                              ...notification,
                              timer: e.target.checked,
                            },
                            "timer",
                            userData?.user.company_id._id
                          );
                        }}
                      />
                    }
                  />
                  {switchLoading.includes("timer") ? (
                    <CircularProgress
                      size={15}
                      style={{ marginLeft: "10px" }}
                    />
                  ) : (
                    ""
                  )}
                </Grid>
              )}
              {userData.user.company_id.notification.physical && (
                <Grid item md={3} xs={12}>
                  <FormControlLabel
                    label="Physically Load On/Off"
                    labelPlacement="start"
                    disabled={
                      disabled.includes("physical") ||
                      switchLoading.includes("physical")
                    }
                    control={
                      <Switch
                        inputProps={{ "aria-label": "Physically Load On/Off" }}
                        checked={notification.physical}
                        onChange={(e) => {
                          setNotification({
                            ...notification,
                            physical: e.target.checked,
                          });
                          handleChange(
                            {
                              ...notification,
                              physical: e.target.checked,
                            },
                            "physical",
                            userData?.user.company_id._id
                          );
                        }}
                      />
                    }
                  />
                  {switchLoading.includes("physical") ? (
                    <CircularProgress
                      size={15}
                      style={{ marginLeft: "10px" }}
                    />
                  ) : (
                    ""
                  )}
                </Grid>
              )}
              {userData.user.company_id.notification.timer_set && (
                <Grid item md={3} xs={12}>
                  <FormControlLabel
                    label="Timer Set/Unset by user"
                    labelPlacement="start"
                    disabled={
                      disabled.includes("timer_set") ||
                      switchLoading.includes("timer_set")
                    }
                    control={
                      <Switch
                        inputProps={{ "aria-label": "Timer Set/Unset by user" }}
                        checked={notification.timer_set}
                        onChange={(e) => {
                          setNotification({
                            ...notification,
                            timer_set: e.target.checked,
                          });
                          handleChange(
                            {
                              ...notification,
                              timer_set: e.target.checked,
                            },
                            "timer_set",
                            userData?.user.company_id._id
                          );
                        }}
                      />
                    }
                  />
                  {switchLoading.includes("timer_set") ? (
                    <CircularProgress
                      size={15}
                      style={{ marginLeft: "10px" }}
                    />
                  ) : (
                    ""
                  )}
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </React.Fragment>
  );
};

export default NotificationSetting;
