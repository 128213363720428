import React, { useContext } from "react";
import styled from "@emotion/styled";
import { Typography, Breadcrumbs, Divider, Link, Box } from "@mui/material";
import { ArrowForwardIosRounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";

const PageTitle = styled(Typography)`
  margin: 0px 0px 0.35em;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.25;
`;

const PathLink = styled(Link)`
  margin: 0px;
  color: rgb(55, 111, 208);
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
`;

const ActiveLink = styled(Typography)`
  margin: 0px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
`;

const Setting = () => {
  let navigate = useNavigate();
  const { userData } = useContext(UserContext);

  return (
    <div>
      <PageTitle>Settings</PageTitle>
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{ display: { xs: "none", lg: "block" } }}
      >
        <PathLink underline="hover" href="/dashboard">
          Dashboard
        </PathLink>
        <ActiveLink>Settings</ActiveLink>
      </Breadcrumbs>
      <Divider style={{ marginTop: 16, marginBottom: 16 }} />
      <>
        <Box>
          {(userData.user.company_id.notification.timer_set ||
            userData.user.company_id.notification.physical ||
            userData.user.company_id.notification.switch ||
            userData.user.company_id.notification.timer) && (
              <Box
                sx={{
                  height: 60,
                  p: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                  border: 1,
                  marginBottom: 2,
                  borderRadius: 3,
                  borderColor: "primary.main",
                }}
                onClick={() => {
                  navigate("/dashboard/setting/notification");
                }}
              >
                <Typography color="secondary">Notification Settings</Typography>
                <ArrowForwardIosRounded color="primary" fontSize="medium" />
              </Box>
            )}

          {/* <Box
            sx={{
              height: 60,
              p: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              cursor: "pointer",
              border: 1,
              borderRadius: 3,
              borderColor: "primary.main",
            }}
            onClick={() => navigate("/dashboard/setting/about")}
          >
            <Typography color="secondary">About App</Typography>
            <ArrowForwardIosRounded color="primary" fontSize="medium" />
          </Box> */}
        </Box>
      </>
    </div>
  );
};

export default Setting;
