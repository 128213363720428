// all the device apis

import axiosIntance from "../helper/axios";

const getDeviceLogs = (device_id,user_id,company_id,page,
    rowsPerPage,
    filter,
    setLogData,
    setTotal,
    setTableLoading,
    setAlertOpen,
    setSeverity,
    setMessage) => {
  axiosIntance
    .get(`/device/logs/${user_id}/${company_id}/${device_id}?page=${page + 1}&limit=${rowsPerPage}&action=${
        filter.action
      }&start_time=${filter.start_time}&end_time=${filter.end_time}`)
    .then((res) => {
        setLogData(res.data.data.logs);
        setTotal(res.data.data.metadata.total_results);
        setTableLoading(false);
    })
    .catch((error) => {
      setAlertOpen(true);
    setSeverity("error");
    console.log(error);
    setMessage(error.response.data.error);
    setTableLoading(false);
    });
};

const getInitialLogs = (user_id,company_id,page,
    rowsPerPage,
    setLogData,
    setTotal,
    setTableLoading,
    setAlertOpen,
    setSeverity,
    setMessage
    ) => {
  axiosIntance
    .get(`/logs/initial/${user_id}/${company_id}?page=${page + 1}&limit=${rowsPerPage}`)
    .then((res) => {
        setLogData(res.data.data.logs);
        setTotal(res.data.data.metadata.total_results);
        setTableLoading(false);
    })
    .catch((error) => {
      setAlertOpen(true);
    setSeverity("error");
    console.log(error);
    setMessage(error.response.data.error);
    setTableLoading(false);
    });
};

export { getDeviceLogs,getInitialLogs };
