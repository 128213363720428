import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./index.css";
import Axios from "./helper/axios";
import { UserContext } from "./context/UserContext";

import routes from "./routes";

// Layouts
import AuthLayout from "./layouts/AuthLayout";
import PublicLayout from "./layouts/PublicLayout";
import PrivateOutlet from "./routes/PrivateOutlet";
import Loader from "./components/Loader";

// Public Pages
import Landing from "./pages/landing";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import PageNotFound from "./pages/general/PageNotFound";
import Otp from "./pages/resetPassword/Otp";
import ResetPassword from "./pages/resetPassword/ResetPassword";

// Private Pages
import Dashboard from "./pages/dashboard";
import MasterControl from "./pages/masterControl";
import Profile from "./pages/profile";
import User from "./pages/user";
import Group from "./pages/group";
import Role from "./pages/role";

import Plans from "./pages/plans";
import Transaction from "./pages/transactions";
import InstallPrompt from "./components/InstallPrompt";
import UpdatePrompt from "./components/UpdatePrompt";
import Metering from "./pages/metering";
import Company from "./pages/company";
import Notification from "./pages/notification/Notification";
import About from "./pages/setting/elements/About";
import NotificationSetting from "./pages/setting/elements/Notifications";
import Setting from "./pages/setting";
import Logs from "./pages/logs";
import EnergyNotification from "./pages/energyNotification.js";

const publicPages = [
  {
    path: routes.landing,
    page: Landing,
    layout: PublicLayout,
  },
  {
    path: routes.login,
    page: Login,
    layout: PublicLayout,
  },
  {
    path: routes.forget,
    page: Otp,
    layout: PublicLayout,
  },
  {
    path: routes.reset,
    page: ResetPassword,
    layout: PublicLayout,
  },
  {
    path: routes.register,
    page: Register,
    layout: PublicLayout,
  },
];

const privatePages = [
  {
    path: routes.company,
    page: Company,
    layout: PublicLayout,
  },
  {
    path: routes.dashboard,
    page: Dashboard,
    layout: AuthLayout,
  },
  {
    path: routes.masterControl,
    page: MasterControl,
    layout: AuthLayout,
  },
  {
    path: routes.profile,
    page: Profile,
    layout: AuthLayout,
  },
  {
    path: routes.user,
    page: User,
    layout: AuthLayout,
  },
  {
    path: routes.energy,
    page: Metering,
    layout: AuthLayout,
  },

  {
    path: routes.energyChart,
    page: Metering,
    layout: AuthLayout,
  },

  {
    path: routes.energyChart,
    page: Metering,
    layout: AuthLayout,
  },
  {
    path: routes.group,
    page: Group,
    layout: AuthLayout,
  },
  {
    path: routes.role,
    page: Role,
    layout: AuthLayout,
  },
  {
    path: routes.plans,
    page: Plans,
    layout: AuthLayout,
  },
  {
    path: routes.transaction,
    page: Transaction,
    layout: AuthLayout,
  },
  {
    path: routes.energyNotification,
    page: EnergyNotification,
    layout: AuthLayout,
  },
  {
    path: routes.logs,
    page: Logs,
    layout: AuthLayout,
  },
  {
    path: routes.notification,
    page: Notification,
    layout: AuthLayout,
  },
  {
    path: routes.setting,
    page: Setting,
    layout: AuthLayout,
  },
  {
    path: routes.notificationSetting,
    layout: AuthLayout,
    page: NotificationSetting,
  },
  {
    path: routes.about,
    layout: AuthLayout,
    page: About
  }
];

function App() {
  const [notificationcount, setnotificationcount] = useState(0);
  const [userData, setUserData] = useState({
    token: undefined,
    user: undefined,
    planData: undefined,
    companies: undefined
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkLoggedin = async () => {
      try {
        let token = localStorage.getItem("auth-token");
        let company = localStorage.getItem("company");
        if (token === null || company === null) {
          localStorage.setItem("auth-token", "");
          token = "";
        }
        if (localStorage.getItem("auth-token") !== "") {
          let userRes = await Axios.get(`/user/current/info/${company}`);
          //   const com= localStorage.getItem("company");
          // const u= userRes.data.data.filter((puser)=>
          //   puser.company_id._id===com)
          userRes = userRes.data.data;
          setUserData({
            token,
            user: userRes,
            planData: userRes.plan
          });
          const notificationcount = await Axios.get(
            `/user/notification/unread/${userRes._id}/${userRes.company_id._id}`
          );
          // console.log(notificationcount);
          setnotificationcount(notificationcount.data.data);
          document.addEventListener("visibilitychange", () =>
            handleVisibilityChange(userRes._id, userRes.company_id._id)
          );
        }
        // console.log(res);
      } finally {
        setLoading(false);
      }
    };

    checkLoggedin();

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);
  navigator.serviceWorker.addEventListener("message", (event) => {
    if (event.data.data.type !== "energy")
      setnotificationcount(notificationcount + 1);
  });

  const handleVisibilityChange = async (user_id, company_id) => {
    if (!document.hidden) {
      const notificationcount = await Axios.get(
        `/user/notification/unread/${user_id}/${company_id}`
      );
      setnotificationcount(notificationcount.data.data);
    }
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Router>
          <UserContext.Provider value={{ userData, setUserData }}>
            <Routes>
              {/* Mapping Public Routes */}
              {publicPages.map(({ path, page: Page, layout: Layout }) => (
                <Route
                  key={path}
                  path={path}
                  element={
                    <Layout notificationcount={notificationcount}>
                      <Page setnotificationcount={setnotificationcount} />
                    </Layout>
                  }
                />
              ))}

              {/* Mapping Private Routes */}
              {privatePages.map(({ path, page: Page, layout: Layout }) => (
                <Route
                  key={path}
                  path={path}
                  element={
                    <PrivateOutlet>
                      <Layout notificationcount={notificationcount}>
                        <Page setnotificationcount={setnotificationcount} />
                      </Layout>
                    </PrivateOutlet>
                  }
                />
              ))}
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </UserContext.Provider>
        </Router>
      )}
      <InstallPrompt />
      <UpdatePrompt />
    </>
  );
}

export default App;
