/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  InputBase,
  IconButton,
  Tooltip,
  Snackbar,
  Alert,
  Button,
} from "@mui/material";
import { Search as SearchIcon, FilterList } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { getAllCompanyPlans } from "../../../api/plans";
import Loader from "../../../components/Loader";
import { UserContext } from "../../../context/UserContext";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#f0f0f0",
  "&:hover": {
    backgroundColor: "#f7f9fc",
  },
  marginLeft: 0,
  marginRight: 10,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const headers = [
  { id: "name", label: "Plan Name" },
  { id: "code", label: "Plan Code" },
  { id: "status", label: "Plan Status" },
  { id: "start_date", label: "Start Date" },
  { id: "end_date", label: "End Date" },
  {
    id: "assosiated_transaction",
    label: "Assosiated Transaction",
  },
];

const PlansTable = (
  {
    refresh,
    setRefresh,
    setFilterOpen,
    filterData
  }
) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(true);
  const [alertOpen, setAlertOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const { userData } = useContext(UserContext);
  useEffect(() => {
    let filter = {
      start_time:
        filterData.start_time !== ""
          ? new Date(filterData.start_time).toISOString()
          : "",
      end_time:
        filterData.end_time !== ""
          ? new Date(filterData.end_time).toISOString()
          : "",
    };
    getAllCompanyPlans(
      userData.user.company_id._id,
      setRows,
      setLoading,
      setAlertOpen,
      page,
      rowsPerPage,
      setTotal,
      filter
    );
  }, [page, rowsPerPage, refresh]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  return (
    <>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleAlertClose}
          severity={"error"}
          sx={{ width: "100%" }}
        >
          Error in fetching plans. Please try after some time
        </Alert>
      </Snackbar>

      {loading ? (
        <Loader data="Plans" />
      ) : (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <div
            style={{
              margin: "16px 8px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">Company Plans</Typography>
            <div style={{ display: "flex", alignItems: "center" }}>
              {/* <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search Plans"
                  inputProps={{ "aria-label": "search" }}
                />
              </Search> */}
              <IconButton onClick={(e) => {
                setFilterOpen(true);
              }}>
                <Tooltip title="Filters" arrow placement="top">
                  <FilterList />
                </Tooltip>
              </IconButton>
              {/* <IconButton>
          <Tooltip title="Add Plans" arrow placement="top">
            <AddBox onClick={handleFormOpen} />
          </Tooltip>
        </IconButton> */}
            </div>
          </div>

          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {headers.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                      <TableCell component="th" scope="row">
                        {row.plan_name}
                      </TableCell>
                      <TableCell>{row.plan_code}</TableCell>
                      <TableCell>
                        <Button
                          size="small"
                          sx={{
                            color:
                              row.plan_status === "active"
                                ? "green"
                                : row.plan_status === "upcoming"
                                  ? "blue"
                                  : "red",
                          }}
                        >
                          {row.plan_status.toUpperCase()}
                        </Button>
                      </TableCell>
                      <TableCell>
                        {row.start_date === undefined ? (
                          "-"
                        ) : (
                          <>
                            {new Date(row.start_date).toLocaleString("en-IN", {
                              timeZone: "Asia/Kolkata",
                            })}
                          </>
                        )}
                      </TableCell>
                      <TableCell>
                        {row.end_date === undefined ? (
                          "-"
                        ) : (
                          <>
                            {new Date(row.end_date).toLocaleString("en-IN", {
                              timeZone: "Asia/Kolkata",
                            })}
                          </>
                        )}
                      </TableCell>
                      <TableCell>
                        {row.transaction_details.transaction_id}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </>
  );
};

export default PlansTable;
