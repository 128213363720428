import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  CssBaseline,
  Container,
  Menu,
  MenuItem,
  Box,
  IconButton,
  Button,
  useScrollTrigger,
} from "@mui/material";
import { Login, Menu as MenuIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const TopNav = ({
  user,
  scrollToSection,
  heroSection,
  aboutSection,
  featureSection,
  contactSection,
}) => {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const pages = [
    { name: "About", sectionRef: aboutSection },
    { name: "Features", sectionRef: featureSection },
    { name: "Contact", sectionRef: contactSection },
  ];

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 550,
  });

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar
        // color={trigger ? 'primary' : 'transparent'}
        elevation={trigger ? 4 : 0}
        sx={
          trigger
            ? { backgroundColor: "#1d1f21" }
            : { backgroundColor: "transparent" }
        }
      >
        <Container maxWidth="lg">
          <Toolbar disableGutters>
            <IconButton
              color="inherit"
              edge="start"
              onClick={() => navigate("/")}
              sx={
                trigger
                  ? {
                      display: { xs: "none", md: "flex" },
                    }
                  : {
                      display: "none",
                    }
              }
            >
              <img
                src="/assets/logo/Logo-Lateral-White.svg"
                alt="Luminox"
                style={{
                  width: "auto",
                  height: 30,
                }}
              />
            </IconButton>

            {/* Navmenu Mobile */}
            <Box sx={{ flexGrow: 0, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="small"
                onClick={handleOpenNavMenu}
                color="light"
                sx={trigger ? { display: "block" } : { dispay: "none" }}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">{page.name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            {/* Logo Mobile */}
            <IconButton
              color="inherit"
              edge="start"
              onClick={() => navigate("/")}
              sx={{
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                ml: 2,
              }}
            >
              <img
                src="/assets/logo/Logo-Lateral-White.svg"
                alt="Luminox"
                style={{
                  width: "auto",
                  height: 30,
                }}
              />
            </IconButton>

            {/* Navmenus Desktop */}
            <Box
              sx={{
                flexGrow: 1,
                display: {
                  xs: "none",
                  md: "flex",
                  marginRight: 86,
                },
                justifyContent: "center",
              }}
            >
              {pages.map((page) => (
                <Button
                  key={page.name}
                  color="light"
                  style={trigger ? { display: "block" } : { display: "none" }}
                  onClick={() => scrollToSection(page.sectionRef)}
                  sx={{ my: 2 }}
                >
                  {page.name}
                </Button>
              ))}
            </Box>

            {/* Login Buttom Desktop */}
            <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
              <Button
                color={trigger ? "light" : "light"}
                onClick={() =>
                  user ? navigate("/dashboard") : navigate("/login")
                }
              >
                {user ? "Dashboard →" : "LOGIN"}
              </Button>
            </Box>

            {/* Login Button Mobile */}
            <Box
              sx={{ flexGrow: 0, display: { xs: "flex", md: "none" }, mr: -1 }}
            >
              <Button
                color={trigger ? "light" : "primary"}
                onClick={() =>
                  user ? navigate("/dashboard") : navigate("/login")
                }
              >
                <Login />
              </Button>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Toolbar />
    </React.Fragment>
  );
};

export default TopNav;
