/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import styled from "@emotion/styled";
import Loader from "../../components/Loader";
import LogsTable from "./elements/LogsTable";
import LogFilter from "./elements/LogFilter";
import { Typography, Breadcrumbs, Divider, Link, Box } from "@mui/material";
import { fetchUserGroups, getGroupWiseDevices } from "../../api/group";
import { UserContext } from "../../context/UserContext";

const PageTitle = styled(Typography)`
  margin: 0px 0px 0.35em;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.25;
`;

const PathLink = styled(Link)`
  margin: 0px;
  color: rgb(55, 111, 208);
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
`;

const ActiveLink = styled(Typography)`
  margin: 0px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
`;
const Logs = () => {
  const { userData } = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const [deviceData, setDeviceData] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filterData, setFilterData] = useState({
    start_time: "",
    end_time: "",
  });
  const [deviceLoad, setDeviceLoad] = useState(false);
  const [GroupData, setGroupData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [fetch, setFetch] = useState(false);

  const [formState, setFormState] = useState({
    action: [],
  });
  const [SelectedGroups, setSelectedGroups] = useState([]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setDeviceLoad(true);
    let dt = [];
    SelectedGroups.map((opt) => dt.push(opt._id));

    getGroupWiseDevices(userData.user.company_id._id,setDeviceData, setDeviceLoad);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch]);

  useEffect(() => {
    fetchUserGroups(userData.user._id,userData.user.company_id._id, setGroupData, setLoading);
  }, []);
  const handleFormClose = () => {
    setFilterOpen(false);
  };
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <Box>
          <PageTitle>Device Logs</PageTitle>
          <Breadcrumbs
            aria-label="breadcrumb"
            sx={{ display: { xs: "none", lg: "block" } }}
          >
            <PathLink underline="hover" href="/dashboard">
              Dashboard
            </PathLink>
            <ActiveLink>Logs</ActiveLink>
          </Breadcrumbs>
          <Divider style={{ marginTop: 16, marginBottom: 16 }} />
          <LogsTable
            deviceData={deviceData}
            loading={loading}
            setFilterOpen={setFilterOpen}
            formState={formState}
            refresh={refresh}
            setRefresh={setRefresh}
            filterData={filterData}
            setFilterData={setFilterData}
            setFormState={setFormState}
            GroupData={GroupData}
            deviceLoad={deviceLoad}
            setSelectedGroups={setSelectedGroups}
            SelectedGroups={SelectedGroups}
            fetch={fetch}
            setFetch={setFetch}
          />
          <LogFilter
            filterOpen={filterOpen}
            handleFilterClose={handleFormClose}
            formState={formState}
            setFormState={setFormState}
            refresh={refresh}
            setRefresh={setRefresh}
            filterData={filterData}
            setFilterData={setFilterData}
          />
        </Box>
      )}
    </div>
  );
};

export default Logs;
