import React from "react";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";

Chart.register(...registerables, zoomPlugin);

const ChartData = ({ data, time, color, label, name, setFilterOpen, startIndex,fetchMoreData,start_time,end_time }) => {
  // console.log(data);
  function debounce(func, wait) {
    let timeout;
    return function(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }
  // Prepare data for the chart
  const chartData = {
    labels: time, // Full time array
    datasets: [
      {
        label: label,
        data: data, // Full data array
        fill: false,
        borderColor: color,
      },
    ],
  };

  // Configuration options for the chart
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    stacked:true,
    // plugins: {
    //   zoom: {
    //     pan: {
    //       enabled: true,
    //       mode: 'x', // Enable horizontal panning
    //       onPanComplete: ({ chart }) => {
    //         const xAxis = chart.scales.x;
    //         if (xAxis.min === 0) {
    //           debounce(fetchMoreData(-1,start_time),10000); // Fetch more data when panning reaches the beginning
              
    //         }
    //         else if(xAxis.max===data.length -1){
    //           debounce(fetchMoreData(1,end_time),10000);
    //         }
    //       },
    //     },
        // zoom: {
        //   wheel: {
        //     enabled: true,
        //   },
        //   pinch: {
        //     enabled: true,
        //   },
        //   mode: 'x', // Enable horizontal zooming
        // },
      // },
      tooltip: {
        callbacks: {
          title: (tooltipItems) => {
            const index = tooltipItems[0].dataIndex;
            return `Timestamp: ${time[index]}`;
          },
          label: (tooltipItem) => {
            const value = tooltipItem.raw;
            return `${name} ${value}`;
          },
        },
      },
    // },
    scales: {
      x: {
        title: {
          display: true,
          text: "Time",
        },
        min:  startIndex<0?data.length+startIndex:startIndex,
        max:  startIndex<0?data.length+startIndex+5:startIndex+5,
      },
      y: {
        title: {
          display: true,
          text: `${name}`,
        },
        min: 0, // y-axis starts at 0
      },
    },
    animation: {
      duration: 0, // Disable animations
    },
  };

  return (
    <div
      style={{
        cursor: "pointer",
      }}
      onDoubleClick={() => setFilterOpen(true)}
    >
      <Line data={chartData} options={chartOptions} />
    </div>
  );
};

export default ChartData;
