import React, { useContext, useEffect, useState } from "react";
import {
  Alert,
  Button,
  Box,
  Card,
  Container,
  Link,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { UserContext } from "./../../context/UserContext";
import Axios from "../../helper/axios";
import { CircularProgress } from "@mui/material";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link
        color="secondary"
        href="https://www.thekaspertech.com/"
        sx={{ textDecoration: "none", fontWeight: 600 }}
      >
        KasperTech
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function Otp() {
  const { userData } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState();
  const [severity, setSeverity] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState("");

  const [number, setNumber] = useState("");
  let navigate = useNavigate();

  useEffect(() => {
    if (userData.user) navigate("/dashboard");
  });

  const handleClose = () => {
    setOpen(false);
  };

  const createOTP = async (e) => {
    // event.preventDefault();
    setLoading(true);
    try {
      e.preventDefault();
      const loginres = await Axios.post("/auth/create-otp", {
        phone_number: number,
      });
      localStorage.setItem("verification_key", loginres.data.verification_key);
      setMessage(loginres.data.message);
      setOpen(true);
      setLoading(false);
      setSeverity("success");
      setOtpSent(true);
    } catch (err) {
      setOpen(true);
      setMessage(err.response.data.error);
      setLoading(false);
      setSeverity("error");
    }
  };

  const verifyOtp = async (e) => {
    setLoading(true);
    try {
      e.preventDefault();

      const res = await Axios.post("/auth/verify-otp", {
        phone_number: number,
        otp_code: otp,
      });

      localStorage.setItem("reset_token", res.data.reset_token);
      localStorage.removeItem("verification_key");

      setMessage(res.data.message);
      setOpen(true);
      setLoading(false);
      setSeverity("success");
      navigate("/reset-password");
    } catch (err) {
      setOpen(true);
      setMessage(err.response.data.error);
      setLoading(false);
      setSeverity("error");
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#213240",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: { xs: "none", sm: "center" },
      }}
    >
      <Box
        sx={{
          width: { xs: "80%", sm: 500 },
          mb: 4,
          mt: { xs: "15vh", sm: 0 },
        }}
      >
        <img
          src="/assets/logo/Logo-Lateral-Light.svg"
          alt="Luminox by KasperTech"
          width="100%"
          height="auto"
        />
      </Box>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          height: { xs: "75vh", sm: "fit-content" },
          borderRadius: { xs: "40px 40px 0 0", sm: "40px" },
        }}
        elevation={8}
      >
        <Container component="main" maxWidth="md">
          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleClose}
              severity={severity}
              sx={{ width: "100%" }}
            >
              {message}
            </Alert>
          </Snackbar>

          <Typography
            sx={{ fontSize: "2.5rem", fontWeight: 600, mt: { xs: 6, sm: 4 } }}
            color="secondary"
          >
            Get OTP
          </Typography>
          {otpSent ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Box
                component="form"
                onSubmit={verifyOtp}
                noValidate
                sx={{ mt: "5%" }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="otp"
                  label="OTP"
                  name="otp"
                  autoFocus
                  value={otp}
                  type="number"
                  onChange={(e) => setOtp(e.target.value)}
                />

                <Button
                  type="submit"
                  fullWidth
                  size="large"
                  variant="contained"
                  color="secondary"
                  sx={{ mt: 3, mb: 2 }}
                >
                  {loading ? (
                    <>
                      Loading{" "}
                      <CircularProgress
                        color="inherit"
                        size={20}
                        style={{ marginLeft: "8px" }}
                      />
                    </>
                  ) : (
                    "Verify OTP"
                  )}
                </Button>

                <Copyright
                  sx={{
                    display: { xs: "none", sm: "block" },
                    mt: 3,
                    mb: 4,
                  }}
                />
              </Box>

              <Copyright
                sx={{
                  position: "absolute",
                  bottom: 0,
                  mb: 1,
                  display: { sm: "none" },
                }}
              />
            </Box>
          ) : (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Box
                component="form"
                onSubmit={createOTP}
                noValidate
                sx={{ mt: "5%" }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="phone_no"
                  label="Phone Number"
                  name="phone_no"
                  autoComplete="phone_no"
                  autoFocus
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                />

                <Button
                  type="submit"
                  fullWidth
                  size="large"
                  variant="contained"
                  color="secondary"
                  sx={{ mt: 3, mb: 2 }}
                >
                  {loading ? (
                    <>
                      Loading{" "}
                      <CircularProgress
                        color="inherit"
                        size={20}
                        style={{ marginLeft: "8px" }}
                      />
                    </>
                  ) : (
                    "Generate OTP"
                  )}
                </Button>

                <Copyright
                  sx={{
                    display: { xs: "none", sm: "block" },
                    mt: 3,
                    mb: 4,
                  }}
                />
              </Box>

              <Copyright
                sx={{
                  position: "absolute",
                  bottom: 0,
                  mb: 1,
                  display: { sm: "none" },
                }}
              />
            </Box>
          )}
        </Container>
      </Card>
    </Box>
  );
}
