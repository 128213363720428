import React, { useContext, useState } from "react";
import { Toolbar, Box, CssBaseline, Snackbar, Alert } from "@mui/material";

import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import BottomAppBar from "../components/BottomAppBar";
import { UserContext } from "../context/UserContext";
import { useNavigate } from "react-router-dom";

const drawerWidth = 240;

const AuthLayout = ({ children,notificationcount }) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [severity, setSeverity] = useState("error");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const { userData } = useContext(UserContext);
navigator.serviceWorker.addEventListener("message", (event) => {
  const payload= event.data;
  if (payload.data.type === "energy") {
    // alert(payload.notification.body);
    setSeverity("error");
    setMessage(`${payload.data.title}\n${payload.data.body}`);
    setAlertOpen(true);
  }
});

  const handleClose = () => {
    setAlertOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Navbar
        drawerWidth={drawerWidth}
        handleDrawerToggle={handleDrawerToggle}
        notificationcount={notificationcount}
      />
      <Sidebar
        mobileOpen={mobileOpen}
        drawerWidth={drawerWidth}
        handleDrawerToggle={handleDrawerToggle}
      />
      <Snackbar
        open={userData.planData.length === 0 && true}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          Plan Expired! Please contact Admin to continue using the dashboard
        </Alert>
      </Snackbar>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        onClick={() => navigate("/dashboard/energy-notification")}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
          cursor: "pointer",
        }}
      >
        <Alert
          onClose={handleClose}
          severity={severity}
          sx={{ width: "100%", cursor: "pointer" }}
        >
          {message}
        </Alert>
      </Snackbar>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          backgroundColor: "#f7f9fc",
          overflowX: "scroll",
        }}
      >
        <Toolbar />
        <Box
          style={{
            minHeight: "calc(100vh - 152px)",
          }}
          sx={{ padding: { lg: "32px" } }}
        >
          {children}
        </Box>
        <BottomAppBar notificationcount={notificationcount} />
        <Footer />
      </Box>
    </Box>
  );
};

export default AuthLayout;
