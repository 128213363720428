// All the apis for company transactions

import axiosIntance from "../helper/axios";

const getAllTransactions = (
  company_id,
  setRows,
  setAlertOpen,
  setLoading,
  page,
  limit,
  setTotal,
  filter
) => {
  setLoading(true)
  axiosIntance
    .get(`/transactions/all/${company_id}?page=${page + 1}&limit=${limit}&start_time=${filter.start_time}&end_time=${filter.end_time}`)
    .then((res) => {
      setRows(res.data.data.transaction_data);
      setTotal(res.data.data.metadata.total_results);
      setLoading(false);
    })
    .catch((err) => {
      setLoading(false);
      setAlertOpen(true);
    });
};

export { getAllTransactions };
